import {AiOutlineLoading} from 'react-icons/ai';

const SpinningFallback: React.FC = () => (
  <div className="mx-auto relative">
    <AiOutlineLoading
      className="absolute right-1/2 animate-spin font-semibold mt-4 ml-4"
      size={64}
    />
  </div>
);

export default SpinningFallback;
