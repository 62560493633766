import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {BasicElement} from '../../helpers/BasicElement';
import {
  NewLevelCommunicatorSendingEvent,
  NewLevelGettingEvent,
} from '../../pages/scriptIntegrations/newLevelPopup/NewLevelPopupTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';

interface NewLevelScriptIntegrationProps extends BasicElement {
  componentId?: string;
}

export interface NewLevelScriptIntegrationRef {
  close: () => void;
}

const NewLevelScriptIntegration = forwardRef<
  NewLevelScriptIntegrationRef,
  NewLevelScriptIntegrationProps
>(({children, componentId}, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const eventMessangerRef =
    useRef<EventSystemRefProps<NewLevelCommunicatorSendingEvent>>(null);

  const OnLoaded = useCallback(() => {
    setIsLoaded(true);
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  useImperativeHandle(ref, () => ({
    close: () => {
      eventMessangerRef.current?.sendMessage({event: 'onClose'});
    },
  }));

  const incomingEventsHandler = useCallback(
    (message: NewLevelGettingEvent) => {
      switch (message.event) {
        case 'checkLoaded':
          if (isLoaded) {
            eventMessangerRef.current?.sendMessage({
              event: 'onLoaded',
            });
          }
          break;
      }
    },
    [isLoaded],
  );

  return (
    <>
      <EventsMessanger<NewLevelGettingEvent, NewLevelCommunicatorSendingEvent>
        componentName={componentId || 'levelupPopup'}
        myRef={eventMessangerRef}
        onMessage={incomingEventsHandler}
        onLoaded={OnLoaded}
      />
      {children}
    </>
  );
});

export default NewLevelScriptIntegration;
