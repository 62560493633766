import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {useResizeDetector} from 'react-resize-detector';
import {
  DialogBaseProps,
  DialogButton,
  DialogProps,
  DialogStyle,
} from '../Contract';
import SimpleDialogButton from './SimpleButton';

interface SimpleDialogProps {
  props: DialogProps;
  base: DialogBaseProps;
  style: DialogStyle;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  props: {data, onButtonPressed},
  base: {onCloseButtonPressed, onHeightChanged, onWidthChanged},
  style,
}) => {
  const {width, height, ref} = useResizeDetector({
    refreshRate: 200,
  });

  useEffect(() => {
    if (!height || !onHeightChanged) return;
    onHeightChanged(height);
  }, [height, onHeightChanged]);

  useEffect(() => {
    if (!width || !onWidthChanged) return;
    onWidthChanged(width);
  }, [onWidthChanged, width]);

  const [innerButtons, setInnerButtons] = useState<DialogButton[][]>();

  useEffect(() => {
    console.log('Setting.....');

    setInnerButtons(() => {
      if (!data?.innerButtons || data.innerButtons?.length === 0) return;

      const elements: DialogButton[][] = [];

      let currentRow = -1;
      let currentIndex = -1;

      data.innerButtons
        .sort((n1, n2) => (n1.row || 0) - (n2.row || 0))
        .forEach((element) => {
          const elementRow = element.row || 0;
          if (elementRow > currentRow) {
            currentRow = elementRow;
            elements.push([]);
            currentIndex++;
          }
          elements[currentIndex].push(element);
        });

      console.log('============================');
      console.log(elements);
      return elements;
    });
  }, [data?.innerButtons]);

  if (!data) return null;
  return (
    <div ref={ref}>
      <div
        className="relative rounded-lg cursor-default h-fit px-5 pb-2 pt-1"
        style={{
          backgroundColor: style.backgroundColor,
          color: style.mainTextColor,
          border: style.borderColor && `1px solid ${style.borderColor}`,
        }}
      >
        <div className="flex border-b border-solid-black/80 py-1">
          {data.title && <div className="text-sm ">{data.title}</div>}
          <div
            className="ml-auto text-[#C4C5C7] hover:text-black cursor-pointer"
            onClick={onCloseButtonPressed}
          >
            <AiOutlineClose />
          </div>
        </div>

        <div className="my-2">{data.text}</div>
        <div className="space-y-2">
          {innerButtons &&
            innerButtons.map((line, index) => (
              <div className="flex gap-2" key={index}>
                {line.map((button) => (
                  <SimpleDialogButton
                    key={button.id}
                    className={classNames(button.flex, 'ml-auto')}
                    onClick={() => onButtonPressed(button.id)}
                    style={button.style}
                    title={button.title}
                    {...style}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SimpleDialog;
