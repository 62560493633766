import {useCallback} from 'react';
import {IceBreakerUiProps} from '../../ui/iceBreaker/Types';

interface IceBreakerUiWrapperProps {
  uiElement: React.FC<IceBreakerUiProps>;

  onLoaded: () => void;
  onIceBreaked: () => void;
}

const IceBreakerUiWrapper: React.FC<IceBreakerUiWrapperProps> = ({
  uiElement,
  onLoaded,
  onIceBreaked,
}) => {
  const getContent = useCallback(() => {
    return uiElement({
      onLoaded,
      numberClicks: 2,
      onBreaked: () => {
        console.log('ice breaked');

        onIceBreaked();
      },
    });
  }, [uiElement, onLoaded, onIceBreaked]);

  return <>{getContent()}</>;
};

export default IceBreakerUiWrapper;
