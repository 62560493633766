import {useCallback, useRef, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useSearchParams} from 'react-router-dom';
import {
  useAnonymousLoadGame,
  useAnonymousSaveGame,
} from '../../../api/Integration/save/SaveMutation';
import SaverEventHandler, {
  SaveIntegrationRef,
} from '../../../components/scriptIntegrations/SaveScriptIntegration';

const SaveWrapper: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [integrationId] = useState(searchParams.get('integrationId'));

  const saverCommunicatorRef = useRef<SaveIntegrationRef>(null);

  const saveGameOnServer = useAnonymousSaveGame({
    onSettled: () => {
      saverCommunicatorRef.current?.onDataSaved();
    },
  });
  const loadGameFromServer = useAnonymousLoadGame({
    onSuccess: (data) => {
      saverCommunicatorRef.current?.onDataLoaded(data);
    },
    onError: () => {
      saverCommunicatorRef.current?.onDataLoadFailed();
    },
  });

  const [cookies, setCookies] = useCookies([`game-save-${integrationId}`]);

  const loadGame = useCallback(
    (platformId?: string) => {
      const localData = cookies[`game-save-${integrationId}`];
      if (localData) {
        saverCommunicatorRef.current?.onDataLoaded({
          id: '',
          platformId: '',
          saveData: localData,
        });
      } else {
        if (platformId) {
          loadGameFromServer.mutate(platformId);
        } else {
          saverCommunicatorRef.current?.onDataLoadFailed();
        }
      }
    },
    [cookies, integrationId, loadGameFromServer],
  );

  const saveGameLocal = useCallback(
    (saveData: string) => {
      setCookies(`game-save-${integrationId}`, JSON.stringify(saveData), {
        sameSite: 'none',
        secure: true,
      });
      saverCommunicatorRef.current?.onDataSaved();
    },
    [integrationId, setCookies],
  );

  return (
    <SaverEventHandler
      ref={saverCommunicatorRef}
      onLoadDataRequest={loadGame}
      onSaveDataRequest={(saveData) => {
        saveGameLocal(saveData.saveData);
        saveGameOnServer.mutate({
          ...saveData,
          saveData: JSON.stringify(saveData.saveData),
        });
      }}
      onLocalSaveRequest={saveGameLocal}
    />
  );
};

export default SaveWrapper;
