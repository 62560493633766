import {useGLTF} from '@react-three/drei';
import * as THREE from 'three';

interface CustomModelProps {
  url: string;
}

const CustomModel: React.FC<CustomModelProps> = ({url}) => {
  const model = useGLTF(url);

  const mroot = model.scene;
  const bbox = new THREE.Box3().setFromObject(mroot);
  //   const cent = bbox.getCenter(new THREE.Vector3());
  const size = bbox.getSize(new THREE.Vector3());
  const maxAxis = Math.max(size.x, size.y, size.z);
  mroot.scale.multiplyScalar(2.0 / maxAxis);

  return (
    <mesh position={[0, -0.8, 0]}>
      <primitive object={model.scene} />
    </mesh>
  );
};

export default CustomModel;
