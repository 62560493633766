import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import {SuitJetpackCharacterBaseModel} from '../../../types/models/v2/bases/SuitJetpackCharacterBase';
import {DefaultModel} from '../types/basic';

interface SuitJetpackCharacterProps extends DefaultModel {
  isJetpackActive?: boolean;
}

const SuitJetpackCharacter: React.FC<SuitJetpackCharacterProps> = ({
  onLoaded,
  position,
  rotation,
  scale,

  isJetpackActive = false,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials} = useGLTF(
    '/models/v2/fullModels/NewOfficeGuy.glb',
  ) as unknown as SuitJetpackCharacterBaseModel;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group
      ref={group}
      dispose={null}
      position={position}
      scale={scale}
      rotation={rotation}
      receiveShadow
      castShadow
    >
      <group name="Scene">
        <group name="Armature">
          <primitive object={nodes.mixamorig7Hips} />
          <skinnedMesh
            name="Fire"
            geometry={nodes.Fire.geometry}
            material={materials.Fire}
            skeleton={nodes.Fire.skeleton}
            visible={isJetpackActive}
          />
          <group name="Human">
            <skinnedMesh
              name="Ch33_Belt"
              geometry={nodes.Ch33_Belt.geometry}
              material={materials.body}
              skeleton={nodes.Ch33_Belt.skeleton}
            />
            <skinnedMesh
              name="Ch33_Belt_1"
              geometry={nodes.Ch33_Belt_1.geometry}
              material={materials.hair}
              skeleton={nodes.Ch33_Belt_1.skeleton}
            />
          </group>
          <skinnedMesh
            name="JetPack"
            geometry={nodes.JetPack.geometry}
            material={materials.jet}
            skeleton={nodes.JetPack.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default SuitJetpackCharacter;
