import {useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import LevelupPopupBasic from '../../../components/levelupPopup/LevelupPopupBasic';
import NewLevelScriptIntegration, {
  NewLevelScriptIntegrationRef,
} from '../../../components/scriptIntegrations/NewLevelScriptIntegration';

const NewLevelWrapper: React.FC = () => {
  const integrationCommunicationRef =
    useRef<NewLevelScriptIntegrationRef>(null);

  const {id} = useParams<{id: string}>();

  return (
    <NewLevelScriptIntegration
      ref={integrationCommunicationRef}
      componentId={id}
    >
      <LevelupPopupBasic
        onClose={() => integrationCommunicationRef.current?.close()}
      />
    </NewLevelScriptIntegration>
  );
};

export default NewLevelWrapper;
