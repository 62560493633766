import {AiOutlineClose} from 'react-icons/ai';
import {
  TournamentTableBasicProps,
  TournamentTableLeaderboardLine,
} from '../Types';
import LeaderboardSimpleLine from './LeaderboardSimpleLine';
import {BsTrophyFill} from 'react-icons/bs';
import classNames from 'classnames';

type LeaderboardProps = TournamentTableBasicProps;

const LeaderboardSimple: React.FC<LeaderboardProps> = ({
  lines,
  title,
  text,
}) => {
  return (
    <div className="rounded-[20px] font-inter w-full lg:w-2/3 shadow-[0px_5px_25px_rgba(0,_0,_0,_0.1)] pl-[5%] pr-[7%] py-[3%] mb-2">
      <h2 className="text-[#02C9BF] font-bold text-2xl md:text-5xl flex items-center gap-2 pb-[4%]">
        <BsTrophyFill />
        Leaderboard
      </h2>
      <p className="text-xs sm:text-base md:text-lg tracking-tight pr-[25%] pb-[5%]">
        Does the De Montfort University oes the De Montfort University Library
        have specialized
      </p>
      <div className="h-3/4 overflow-scroll">
        {lines
          .sort(
            (
              a: TournamentTableLeaderboardLine,
              b: TournamentTableLeaderboardLine,
            ) => a.place - b.place,
          )
          .map((line, index) => {
            return (
              <div
                key={line.place}
                className="mb-[3%] flex gap-[3%] items-center"
              >
                <div
                  className={classNames(
                    'rounded-2xl text-white w-[14%] flex justify-center items-center text-xs sm:text-xl font-bold tracking-wide aspect-[70_/_28] h-fit',
                    index === 0 ? 'bg-[#5DC466]' : 'bg-[#8E8E92]',
                    index === lines.length - 1 && 'bg-[#02C9BF]',
                  )}
                >
                  {line.place}th
                </div>
                <div className="border-solid border-[#B7B7B9] w-full border-b-[0.3px] text-xs sm:text-xl flex justify-between p-[1%] pr-[5%] pl-0">
                  <div>{line.name}</div>
                  <div
                    className={classNames(
                      'text-[#8E8E92] w-[15%]',
                      index === 0 ? 'text-[#5DC466]' : 'text-[#8E8E92]',
                      index === lines.length - 1 && 'text-[#02C9BF]',
                    )}
                  >
                    ${line.customProperties[0].value}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LeaderboardSimple;
