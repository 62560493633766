import classNames from 'classnames';

interface FlyingCardHeaderProps extends React.HTMLProps<HTMLHeadingElement> {
  text: string;
}

const FlyingCardHeader: React.FC<FlyingCardHeaderProps> = ({
  text,
  className,
  ...other
}) => {
  return (
    <h3
      className={classNames(
        'text-center font-beVietnamPro font-semibold leading-[77%] tracking-[-0.321px] text-white text-[2.3vw]',
        className,
      )}
      {...other}
    >
      {text}
    </h3>
  );
};
export default FlyingCardHeader;
