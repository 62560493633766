import {AxiosInstance} from 'axios';
import MockAdapter from 'axios-mock-adapter';
import {UseChatMock} from './Integration/gpt/mock/ChatMock';
import {UseTournamentMock} from './Integration/tournament/mock/TournamentMock';
import {UsePingMocks} from './ping/mock/PingMock.dev';
import {UseTournamentTableMock} from './Integration/tournament/mock/TournamentTableMock';

export default function UseMocks(axiosInstance: AxiosInstance) {
  const mock = new MockAdapter(axiosInstance, {delayResponse: 0});

  UsePingMocks(mock);

  mock.onAny().passThrough();
}

export function UseProdMocks(axiosInstance: AxiosInstance) {
  const mock = new MockAdapter(axiosInstance, {delayResponse: 0});

  UseTournamentMock(mock);
  UseChatMock(mock);
  UseTournamentTableMock(mock);

  mock.onAny().passThrough();
}
