import {
  forwardRef,
  useCallback,
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  TournamentTableCommunicatorAuthGettingEvent,
  TournamentTableCommunicatorSendingEvent,
} from '../../../pages/scriptIntegrations/tournamentTable/CommonTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';

interface TournamentTableIntegrationProps {
  loadByUserId: (userId: string) => void;
}

export interface TournamentTableIntegrationRef {
  onChildLoaded: () => void;
}

const TournamentTableEventHandler = forwardRef<
  TournamentTableIntegrationRef,
  TournamentTableIntegrationProps
>(({loadByUserId}, ref) => {
  const eventMessangerRef =
    useRef<EventSystemRefProps<TournamentTableCommunicatorSendingEvent>>(null);

  const incomingEventsHandler = useCallback(
    (message: TournamentTableCommunicatorAuthGettingEvent) => {
      switch (message.event) {
        case 'useForUser':
          loadByUserId(message.userId);
          break;
      }
    },
    [loadByUserId],
  );

  const [loaded, setLoaded] = useState({
    communicator: false,
    children: false,
  });

  useImperativeHandle(ref, () => ({
    onChildLoaded() {
      setLoaded((old) => ({...old, children: true}));
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  useEffect(() => {
    if (loaded.children && loaded.communicator) OnLoaded();
  }, [OnLoaded, loaded]);

  return (
    <EventsMessanger<
      TournamentTableCommunicatorAuthGettingEvent,
      TournamentTableCommunicatorSendingEvent
    >
      componentName="tournamentTable"
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={() => setLoaded((old) => ({...old, communicator: true}))}
    />
  );
});

export default TournamentTableEventHandler;
