import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {useResizeDetector} from 'react-resize-detector';
import DataFieldFactory from '../../../dataFields/DataFieldFactory';
import {DialogBaseProps, DialogStyle} from '../Contract';
import {DataFieldResponse, DialogFormProps} from '../FormsPluginContract';
import SimpleDialogButton from './SimpleButton';

interface SimpleFormDialogProps {
  props: DialogFormProps;
  base: DialogBaseProps;
  style: DialogStyle;
}

const SimpleFormDialog: React.FC<SimpleFormDialogProps> = ({
  props: {data, onButtonPressed, onFormCompleted},
  base: {onCloseButtonPressed, onHeightChanged, onWidthChanged},
  style,
}) => {
  const {width, height, ref} = useResizeDetector({
    refreshRate: 200,
  });

  useEffect(() => {
    if (!height || !onHeightChanged) return;
    onHeightChanged(height);
  }, [height, onHeightChanged]);

  useEffect(() => {
    if (!width || !onWidthChanged) return;
    onWidthChanged(width);
  }, [onWidthChanged, width]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState<DataFieldResponse[]>(
    data?.fields.map((x) => ({id: x.id, value: x.defaultValue || ''})) || [],
  );

  const onFormUpdated = useCallback(
    (isValid: boolean, value?: DataFieldResponse) => {
      if (!isValid || !value) {
        setIsFormValid(false);
        return;
      }

      setIsFormValid(true);
      setFormData((old) => {
        const elem = old.find((x) => x.id === value.id);
        if (elem) {
          elem.value = value.value;
          return [...old];
        }
        return old;
      });
    },
    [],
  );

  if (!data) return null;
  return (
    <div ref={ref}>
      <div
        className="relative rounded-lg cursor-default h-fit px-5 pb-2 pt-1"
        style={{
          backgroundColor: style.backgroundColor,
          color: style.mainTextColor,
          border: style.borderColor && `1px solid ${style.borderColor}`,
        }}
      >
        <div className="flex border-b border-solid-black/80 py-1">
          {data.title && <div className="text-sm ">{data.title}</div>}
          <div
            className="ml-auto text-[#C4C5C7] hover:text-black cursor-pointer"
            onClick={onCloseButtonPressed}
          >
            <AiOutlineClose />
          </div>
        </div>

        <div className="my-2">{data.text}</div>

        <div
          className={classNames(!data.isSingleLine && 'flex-col', 'flex gap-2')}
        >
          {data.innerButtons && (
            <>
              {data.fields.map((val) => (
                <DataFieldFactory
                  containerProps={{className: val.flex}}
                  key={val.id}
                  data={val}
                  onDataUpdated={onFormUpdated}
                />
              ))}
              {data.innerButtons.map((x) => (
                <SimpleDialogButton
                  key={x.id}
                  className={classNames(x.flex, 'ml-auto')}
                  onClick={() => {
                    if (x.isSubmit === true) {
                      onFormCompleted(formData);
                    }
                    onButtonPressed(x.id);
                  }}
                  style={x.style}
                  title={x.title}
                  disabled={!x.isSubmit ? false : !isFormValid}
                  {...style}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimpleFormDialog;
