import MockAdapter from 'axios-mock-adapter/types';
import {ChatRequestDAO} from '../dao/ChatRequest';
import {ChatResponseDTO} from '../dto/ChatRequest';

export const UseChatMock = (adapter: MockAdapter): void => {
  adapter
    .onPost(/\/Chat\/GetResponse/)
    .reply<ChatResponseDTO[]>((statusOrCallback) => {
      const data = JSON.parse(statusOrCallback.data) as ChatRequestDAO;

      if (data.promptId === 'summarize-77B915F23C9C') {
        switch (data.queryId) {
          case 'sampler-pack-of-three-each-bag-3oz-whole-beans-specialt-coffee-85-q-grader-score':
            return [
              200,
              {
                response: `Ethiopia Dumerso Yirgacheffe - Washed - Q 87.75. Ethiopia Sidama Bekele Belachew - Natural - Q 88.0.
                512 Espresso Blend - Washed - Peru & Mexico.`,
              },
            ];

          case 'sample-ethiopia-dumerso-yirgacheffe-washed-3oz-whole-beans-specialty-coffee-q-87-75':
            return [
              200,
              {
                response: `Experience our specialty light roast coffee.  Enjoy delightful notes of honey, raspberry, and floral flavors. Dumerso farm: family-owned, traditional, sustainable.`,
              },
            ];

          case 'sample-ethiopia-sidama-bekele-belachew-natural-3oz-whole-beans-specialty-coffee-q-88-0':
            return [
              200,
              {
                response: `Experience our specialty light roast coffee with natural processing. It boasts a Q-Grader Score of 88 and tantalizing tasting notes of morning blueberry, premium milk chocolate, and juicy flavors.`,
              },
            ];

          case 'sample-512-espresso-blend-washed-3oz-whole-beans-peru-mexico':
            return [
              200,
              {
                response: `Experience our medium roast coffee blend with washed process. Taste the harmonious flavors of cocoa, brown sugar, almond butter, and orange.`,
              },
            ];

          //5
          case 'guatemala-el-chalun-washed-in-sleeve-12oz-whole-beans-specialty-coffee-q-87':
            return [
              200,
              {
                response: `Our specialty light roast coffee is carefully washed and offers a Q-Grader Score of 87. Experience the delightful tasting notes of almond, meyer lemon, and syrupy flavors.`,
              },
            ];

          case 'ethiopia-dumerso-yirgacheffe-washed-in-sleeve':
            return [
              200,
              {
                response: ` Our specialty light roast coffee, processed using the washed method, offers a Q-Grader Score of 87.75. Discover delightful tasting notes of natural honey, raspberry, and floral flavors.`,
              },
            ];

          case 'ethiopia-sidama-bekele-belachew-natural-in-sleeve':
            return [
              200,
              {
                response: ` Our specialty light roast coffee, processed naturally, presents a Q-Grader Score of 88. Savor the delightful tasting notes of morning blueberry, premium milk chocolate, and juicy flavors.`,
              },
            ];

          case '512-espresso-blend-washed-12oz-whole-beans-peru-mexico':
            return [
              200,
              {
                response: `Experience our medium roast coffee blend, crafted with washed process. Enjoy the harmonious flavors of cocoa, brown sugar, almond butter, and orange.`,
              },
            ];

          case 'colombia-el-vergel-decaf-in-sleeve':
            return [
              200,
              {
                response: `Indulge in the richness of our decaffeinated medium roast coffee. Processed using the Sugar Cane (E.A.) method, it offers a Q-Grader Score of 84.75. Enjoy the delightful tasting notes of dark chocolate, cinnamon vanilla, and shortbread.`,
              },
            ];

          case 'single-serve-pour-overs-ethiopia-dumerso-yirgacheffe-washed-in-sleeve-12oz-whole-beans-specialty-coffee-q-87-75':
            return [
              200,
              {
                response: `Our light roast coffee offers a Q-Grader Score of 87.75. Discover delightful tasting notes of natural honey, raspberry, and floral flavors.`,
              },
            ];

          case 'single-serve-portable-pour-overs-ethiopia-bekele-belachew-specialty-coffee-q-88-natural-for-10-cups-per-250ml-8oz':
            return [
              200,
              {
                response: `Our specialty light roast coffee, preground for your convenience, is processed naturally and offers a Q-Grader Score of 88. Discover the delightful tasting notes of morning blueberry, premium milk chocolate, and juicy flavors.`,
              },
            ];
          case 'single-serve-portable-pour-overs-mix-ethiopia-bekele-belachew-natural-q-88-ethiopia-dumerso-washed-q-87-colombia-el-vergel-decaf-q-84-specialty-coffee-for-10-cups-per-250ml-8oz':
            return [
              200,
              {
                response: `Our specialty light roast coffee, preground for your convenience, is sourced from family farm in Sidama. It offers tantalizing tasting notes of morning blueberry, premium milk chocolate, and juicy flavors. `,
              },
            ];

          case 'costa-rica':
            return [
              200,
              {
                response: `Our medium roast coffee, grown by micro farms in Alajuela, offers a delightful tasting profile of sweet apple, raisin, and honey. Certified as SHB/EP, it is cultivated at altitudes of 1300-1445 M in volcanic loam soil.`,
              },
            ];

          case 'colombia':
            return [
              200,
              {
                response: `Our medium roast coffee from Medellin, Antioquia, offers a tantalizing tasting profile of dried orange, berry, and chocolate. Grown by smallholder farmers at altitudes of 1300-1500 M, it features varieties such as Castillo, Caturra, Colombia, and Typica.`,
              },
            ];

          case 'brazil-santos':
            return [
              200,
              {
                response: `Our medium roast coffee, grown at Fazenda Santa Barbara in Parana and Sao Paulo, offers an elegant and smooth cup with delightful cocoa notes. Cultivated at altitudes of 750-1050 M in volcanic loam soil, it features the Catuai and Mundo Novo varieties.`,
              },
            ];

          case 'bali-blue':
            return [
              200,
              {
                response: `Our med-dark roast coffee, grown by smallholder farmers in Kintamani, Bali, offers a tantalizing tasting profile of dark chocolate, molasses, and brown sugar. Certified organic, it features varieties such as Bourbon, Typica, and Catimor.`,
              },
            ];
        }
      }

      if (data.promptId === 'country-825DE1E141CF') {
        switch (data.queryId) {
          case 'sampler-pack-of-three-each-bag-3oz-whole-beans-specialt-coffee-85-q-grader-score':
          case 'sample-ethiopia-dumerso-yirgacheffe-washed-3oz-whole-beans-specialty-coffee-q-87-75':
          case 'sample-ethiopia-sidama-bekele-belachew-natural-3oz-whole-beans-specialty-coffee-q-88-0':
          case 'ethiopia-sidama-bekele-belachew-natural-in-sleeve':
          case 'ethiopia-dumerso-yirgacheffe-washed-in-sleeve':
          case 'single-serve-pour-overs-ethiopia-dumerso-yirgacheffe-washed-in-sleeve-12oz-whole-beans-specialty-coffee-q-87-75':
          case 'single-serve-portable-pour-overs-ethiopia-bekele-belachew-specialty-coffee-q-88-natural-for-10-cups-per-250ml-8oz':
          case 'single-serve-portable-pour-overs-mix-ethiopia-bekele-belachew-natural-q-88-ethiopia-dumerso-washed-q-87-colombia-el-vergel-decaf-q-84-specialty-coffee-for-10-cups-per-250ml-8oz':
            return [
              200,
              {
                //pb=!1m18!1m12!1m3!1d6785471.607874778!2d35.998683676842695!3d9.054361341609754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1635d0cedd6cfd2b%3A0x7bf6a67f5348c55a!2sEthiopia!5e0!3m2!1sen!2sbg!4v1684861387974!5m2!1sen!2sbg
                response: `Ethiopia`,
              },
            ];

          case 'sample-512-espresso-blend-washed-3oz-whole-beans-peru-mexico':
          case '512-espresso-blend-washed-12oz-whole-beans-peru-mexico':
            return [
              200,
              {
                //?pb=!1m18!1m12!1m3!1d16138228.865111982!2d-85.70877133254085!3d-9.064010895682422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c850c05914f5%3A0xf29e011279210648!2sPeru!5e0!3m2!1sen!2sbg!4v1684870651805!5m2!1sen!2sbg
                response: `Mexico & Peru`,
              },
            ];

          case 'guatemala-el-chalun-washed-in-sleeve-12oz-whole-beans-specialty-coffee-q-87':
            return [
              200,
              {
                //pb=!1m18!1m12!1m3!1d1966366.453055534!2d-91.55696989651547!3d15.721515096662465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8588135036e7506b%3A0x35982b375b84d5bb!2sGuatemala!5e0!3m2!1sen!2sbg!4v1684870692771!5m2!1sen!2sbg
                response: `Guatemala`,
              },
            ];

          case 'colombia-el-vergel-decaf-in-sleeve':
            return [
              200,
              {
                response: `Guatemala`,
              },
            ];

          case 'costa-rica':
            return [
              200,
              {
                //pb=!1m18!1m12!1m3!1d80353.9813276266!2d-84.26166659263002!3d10.02388877235476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0f9c871fbddb3%3A0xb1f90fbc26bf36cb!2sAlajuela%20Province%2C%20Alajuela%2C%20Costa%20Rica!5e0!3m2!1sen!2sbg!4v1684870735485!5m2!1sen!2sbg
                response: `Alajuela, Costa Rica`,
              },
            ];

          case 'colombia':
            return [
              200,
              {
                //https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63458.359663248215!2d-75.62249703319596!3d6.244287165223728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4428dfb80fad05%3A0x42137cfcc7b53b56!2sMedell%C3%ADn%2C%20Medellin%2C%20Antioquia%2C%20Colombia!5e0!3m2!1sen!2sbg!4v1684870762968!5m2!1sen!2sbg
                response: `Medellin, Antioquia, Colombia`,
              },
            ];

          case 'brazil-santos':
            return [
              200,
              {
                //https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19182570.37342249!2d-54.52090786747562!3d-9.233225664380829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9c59c7ebcc28cf%3A0x295a1506f2293e63!2sBrazil!5e0!3m2!1sen!2sbg!4v1684870796874!5m2!1sen!2sbg
                response: `Parana and Sao Paulo Brazil`,
              },
            ];

          case 'bali-blue':
            return [
              200,
              {
                //https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126352.9910698088!2d115.26242100581824!3d-8.249826157621841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd1f46fa4effc21%3A0x4030bfbca7d2c20!2sKintamani%2C%20Bangli%20Regency%2C%20Bali%2C%20Indonesia!5e0!3m2!1sen!2sbg!4v1684870815996!5m2!1sen!2sbg
                response: `Kintamani, Bali, Indonesia`,
              },
            ];
        }
      }
      return [200, {}];
    });
};
