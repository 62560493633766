import {useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FlyingCardTypes} from './FlyingCardsFactory';
import FlyingCardsEventHandler, {
  FlyingCardsIntegrationRef,
} from '../../../components/scriptIntegrations/flyingCardsEventHandler/FlyingCardsEventHandler';
import FlyingCardsAnimations from '../../../components/flyingCards/animations/FlyinngCardsAnimations';
import stemmyCardCover from '../../../assets/flyingCards/cardCover.png';
import {
  InformingCardProps,
  TaskCardProps,
} from '../../../components/ui/informingCard/Types';
import exp from '../../../assets/flyingCards/exp.png';

const FlyingCardsWrapper: React.FC = ({}) => {
  const {type, id} = useParams<{type: FlyingCardTypes; id: string}>();

  const [localData, setLocalData] = useState<{
    flyingCardsData: InformingCardProps[] | TaskCardProps[];
    coverCard: string;
  }>({
    flyingCardsData: [
      {image: exp, text: '5 active', title: 'QUESTS', id: 'basicCardId'},
      {image: exp, text: '5 active', title: 'AWARDS', id: 'basicCardId2'},
      {image: exp, text: '5 active', title: 'SEASON', id: 'basicCardId3'},
    ],
    coverCard: stemmyCardCover,
  });

  const flyingCardsIntegrationRef = useRef<FlyingCardsIntegrationRef>(null);

  return (
    <>
      <FlyingCardsEventHandler
        componentId={id || 'flyingCards'}
        ref={flyingCardsIntegrationRef}
        refresh={() => {
          // refresh from server
        }}
        spawnCards={(flyingCardsData, coverCard) => {
          setLocalData({
            flyingCardsData,
            coverCard,
          });
        }}
      />

      <FlyingCardsAnimations
        flyingCardsData={localData.flyingCardsData}
        typeCard={type}
        coverCard={localData.coverCard}
        cardOpenedById={(idd) =>
          flyingCardsIntegrationRef.current?.cardOpenedById(idd)
        }
      />
    </>
  );
};

export default FlyingCardsWrapper;
