import React, {useEffect} from 'react';
import {BlockBlockerUiProps} from '../Types';
import {RiArrowRightSLine, RiLockFill} from 'react-icons/ri';
import './BlockBlockerUiGame.scoped.css';

const BlockBlockerUiGame: React.FC<BlockBlockerUiProps> = ({
  onLoaded = () => {},
  title,
  subtitle,
  text,
  buttonTitle,
  onButtonClick,
}) => {
  useEffect(() => {
    onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="absolute w-full h-full top-0 left-0 bg-[rgba(0,_0,_0,_0.8)] z-50">
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="w-[15%] aspect-square">
          <RiLockFill className="w-full h-full text-[#C8FFEE] animate-pulse" />
        </div>
      </div>
      <div className="mx-auto py-[2%] w-[83%] h-full flex flex-col justify-between font-beVietnamPro text-white relative">
        <h2 className="pt-[3%] text-game-3xl lg:text-game-2xl">{title}</h2>
        <div className="text-game-lg lg:text-game-xs">
          <div className="py-[2%] flex flex-col gap-[5%]">
            <h3 className="tracking-[-0.78px] text-game-xl lg:text-game-base">
              {subtitle}
            </h3>
            {text.split('. ').map((t) => {
              return (
                <p className="opacity-80 font-light" key={t}>
                  {t}.
                </p>
              );
            })}
            <div className="flex items-center gap-[2%]">
              View <RiArrowRightSLine />
            </div>
          </div>
          <div
            className="w-full rounded-[11px] bg-[#DE046D] flex justify-center items-center cursor-pointer py-[2%] text-game-xl lg:text-game-xs font-bold hover:opacity-70"
            onClick={onButtonClick}
          >
            {buttonTitle}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlockBlockerUiGame;
