import {Canvas} from '@react-three/fiber';
import {OrbitControls, useGLTF} from '@react-three/drei';
import * as THREE from 'three';
import Skybox from './SkyBox';
import CustomModel from './CustomModel';

export interface SkyboxWithModelAnimationsProps {
  gtlf?: string;
  imageUrl: string;
  imageDepth: string;
}

const SkyboxWithModelAnimations: React.FC<SkyboxWithModelAnimationsProps> = ({
  gtlf,
  imageUrl,
  imageDepth,
}) => {
  //   bbox.setFromObject(mroot);
  //   bbox.getCenter(cent);
  //   bbox.getSize(size);
  //   Reposition to 0,halfY,0
  //   mroot.position.copy(cent).multiplyScalar(-1);
  //   mroot.position.y -= size.y * 0.5;

  return (
    <div className="w-screen h-screen">
      <Canvas
        shadows
        camera={{rotation: [0, 0, 0], position: [0, 1.15, 5], fov: 45}}
      >
        <ambientLight color="#fff" intensity={1} />
        <pointLight color="#fff" position={[0, -10, 0]} intensity={1} />
        {/* <pointLight color="#fff" intensity={100} position={[10, -10, 10]} />
        <pointLight color="#fff" intensity={100} position={[10, -10, -10]} />
        <pointLight color="#fff" intensity={100} position={[-10, 10, -10]} /> */}
        {/* <ambientLight color="#fff" intensity={1} />
        <pointLight color="#fff" position={[0, -10, 0]} intensity={2000} /> */}
        <Skybox imageUrl={imageUrl} imageDepth={imageDepth} />

        {gtlf && <CustomModel url={gtlf} />}

        <OrbitControls />
      </Canvas>
    </div>
  );
};

export default SkyboxWithModelAnimations;
