import {forwardRef} from 'react';
import {BasicElement} from '../../../helpers/BasicElement';

export interface TournamentTasksScriptIntegrationProps extends BasicElement {
  //   componentId: string;
  //   characterRef: React.RefObject<OnboardingJetpackRef>;
  //   isAnimationLoaded: boolean;
}

export interface TournamentTasksScriptIntegrationRef {
  // onAnimationLoaded: () => void;
  //   onAnimationFinished: (animation: JetpackAnimationEvent) => void;
}

const TournamentTaskEventHandler = forwardRef<
  TournamentTasksScriptIntegrationRef,
  TournamentTasksScriptIntegrationProps
>(({children}, ref) => {
  return <div>{children}</div>;
});

export default TournamentTaskEventHandler;
