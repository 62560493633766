import {AnimationFadeData} from './AnimationLoader';

export const DefaultFadeAnimation: AnimationFadeData = {
  fadeInDuration: 0.2,
  fadeOutDuration: 0.2,
  isReset: true,
};

export const InstantFadeAnimation: AnimationFadeData = {
  fadeInDuration: 0,
  fadeOutDuration: 0,
  isReset: true,
};
