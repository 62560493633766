import {useRef} from 'react';
import BlockBlockerEventHandler, {
  BlockBlockerIntegrationRef,
} from '../../../components/scriptIntegrations/blockBlocker/BlockBlockerEventHandler';
import BlockBlockerUiWrapper from '../../../components/scriptIntegrations/blockBlocker/BlockBlockerUiWrapper';
import BlockBlockerUiGame from '../../../components/ui/blockBlocker/gameStyle/BlockBlockerUiGame';

const BlockBlockerGameWrapper: React.FC = () => {
  const blockBlockerRef = useRef<BlockBlockerIntegrationRef>(null);

  return (
    <>
      <BlockBlockerEventHandler
        ref={blockBlockerRef}
        refresh={() => {
          console.log('refresh event');
        }}
      />
      <BlockBlockerUiWrapper
        uiElement={BlockBlockerUiGame}
        onLoaded={() => blockBlockerRef.current?.onLoaded()}
        onButtonClicked={() => blockBlockerRef.current?.onButtonClicked()}
      />
    </>
  );
};

export default BlockBlockerGameWrapper;
