import {Ref, useEffect, useImperativeHandle} from 'react';

export interface BaseMessagingEvent {
  event: string;
}

interface ScriptGameCommunicator<T extends BaseMessagingEvent> {
  target: string;
  element: string;

  event: T;
}

interface EventSystemProps<TInEvents extends BaseMessagingEvent> {
  onMessage: (message: TInEvents) => void;
  componentName: string;
  onLoaded?: () => void;
}

export interface EventSystemRefProps<TOutEvents extends BaseMessagingEvent> {
  sendMessage: (message: TOutEvents) => void;
}

// TODO: Remake. Not a component, but a class
const EventsMessanger = <
  TInEvents extends BaseMessagingEvent,
  TOutEvents extends BaseMessagingEvent,
>({
  onMessage,
  componentName,
  onLoaded,
  myRef,
}: EventSystemProps<TInEvents> & {
  myRef: Ref<EventSystemRefProps<TOutEvents>>;
}) => {
  useImperativeHandle(myRef, () => ({
    sendMessage(message) {
      const data: ScriptGameCommunicator<TOutEvents> = {
        target: 'stemmy-script',
        element: componentName,
        event: message,
      };
      parent.postMessage(data, '*');
    },
  }));

  useEffect(() => {
    const onMessageRecieved = (event: {
      data: ScriptGameCommunicator<TInEvents>;
    }) => {
      if (
        event.data.target === 'stemmy-iframe' &&
        event.data.element === componentName
      ) {
        onMessage(event.data.event);
      }
    };

    window.addEventListener('message', onMessageRecieved);

    return () => {
      window.removeEventListener('message', onMessageRecieved);
    };
  }, [onMessage, componentName]);

  useEffect(() => {
    if (onLoaded) onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default EventsMessanger;
