import React from 'react';
import {
  OnboardingChestProps,
  OnboardingChestRef,
} from '../../../components/animatedCharacters/onboardingChest/Contract';
import ChestCharacterBowBox from '../../../components/animatedCharacters/onboardingChest/ChestCharacterBowBox';
import ChestCharacterRedBox from '../../../components/animatedCharacters/onboardingChest/ChestCharacterRedBox';

export type ChestTypes = 'bowBox' | 'redBox';

interface ChestFactoryProps {
  type: ChestTypes;
  properties: OnboardingChestProps;
}

const ChestFactory = React.forwardRef<OnboardingChestRef, ChestFactoryProps>(
  ({type, properties}, ref) => {
    switch (type) {
      case 'bowBox':
        return <ChestCharacterBowBox {...properties} ref={ref} />;
      case 'redBox':
        return <ChestCharacterRedBox {...properties} ref={ref} />;
    }
  },
);

export default ChestFactory;
