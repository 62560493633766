import {useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import DialogScriptIntegration, {
  DialogFormsPluginRef,
  DialogScriptIntegrationRef,
} from '../../../components/scriptIntegrations/DialogIntegration';
import {DialogData, DialogStyle} from '../../../components/ui/dialog/Contract';
import {DialogFormsData} from '../../../components/ui/dialog/FormsPluginContract';
import {DialogGptData} from '../../../components/ui/dialog/GptPluginContract';
import {DialogMapData} from '../../../components/ui/dialog/MapPluginContract';
import DialogStyleFactory, {DialogType} from './DialogStyleFactory';

export type DialogPlugin = 'default' | 'map' | 'forms' | 'gpt';

const DialogWrapper: React.FC = () => {
  const {type, id} = useParams<{type: DialogType; id: string}>();

  const ref = useRef<DialogScriptIntegrationRef & DialogFormsPluginRef>(null);

  const [dialogType] = useState<DialogType>(type || 'simple');
  const [dialogPlugin, setDialogPlugin] = useState<DialogPlugin>('default');

  const [dialogDefaultProps, setDialogProps] = useState<DialogData>();
  const [dialogFormsProps, setDialogFormProps] = useState<DialogFormsData>();
  const [dialogGptProps, setDialogGptProps] = useState<DialogGptData>();
  const [dialogMapProps, setDialogMapProps] = useState<DialogMapData>();

  const [dialogStyle, setDialogStyle] = useState<DialogStyle>({
    mainColor: '#808080',
    oppositeColor: '#fff',

    borderColor: '#808080',

    mainTextColor: '#000',
    backgroundColor: '#fff',
  });

  return (
    <DialogScriptIntegration
      ref={ref}
      componentId={id || 'dialog'}
      clearDialog={() => {
        setDialogProps(undefined);
        setDialogFormProps(undefined);
        setDialogGptProps(undefined);
        setDialogMapProps(undefined);
      }}
      updateDialog={(data) => {
        setDialogProps(data);
        setDialogPlugin('default');
      }}
      updateDialogForms={(data) => {
        setDialogFormProps(data);
        setDialogPlugin('forms');
      }}
      updateDialogGpt={(data) => {
        setDialogGptProps(data);
        setDialogPlugin('gpt');
      }}
      updateDialogMap={(data) => {
        setDialogMapProps(data);
        setDialogPlugin('map');
      }}
      updateStyle={setDialogStyle}
    >
      <DialogStyleFactory
        type={dialogType}
        plugin={dialogPlugin}
        baseProps={{
          onHeightChanged: (val) => {
            ref.current?.onHeightChanged(val);
          },
          onWidthChanged: (val) => {
            ref.current?.onWidthChanged(val);
          },
          onCloseButtonPressed() {
            ref.current?.onCloseButtonPressed();
          },
        }}
        defaultProperties={{
          data: dialogDefaultProps,
          onButtonPressed: (buttonId) => {
            ref.current?.onButtonPressed(buttonId);
          },
        }}
        formProperties={{
          data: dialogFormsProps,
          onButtonPressed: (buttonId) => {
            ref.current?.onButtonPressed(buttonId);
          },
          onFormCompleted: (val) => {
            ref.current?.onFormApply(val);
          },
        }}
        gptProperties={{
          data: dialogGptProps,
          onButtonPressed: (buttonId) => {
            ref.current?.onButtonPressed(buttonId);
          },
        }}
        mapProperties={{
          data: dialogMapProps,
          onButtonPressed: (buttonId) => {
            ref.current?.onButtonPressed(buttonId);
          },
        }}
        style={dialogStyle}
      />
    </DialogScriptIntegration>
  );
};

export default DialogWrapper;
