import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {queryClient} from '../../../app/App';
import {UseShortMutationOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../../services/globalNotification/errorHandler';
import APIRequest from '../../Api';
import {ApiError} from '../../basicTypes/ApiError';
import {
  DynamicTournamentRequestDTO,
  TournamentAnonymusReqeust,
  TournamentRecordDAO,
  TournamentReqeust,
} from './dao/TournamentRecordDAO';
import {TournamentAnonymusDTO} from './dto/TournamentDTO';
import {GetUserTournamentKey} from './Tournament';

const SetTournamentKey = 'integration/tournament/set';

export const useSetTournamentData = (
  options?: UseShortMutationOptions<
    TournamentAnonymusDTO[],
    AxiosError<ApiError>,
    TournamentRecordDAO
  >,
) => {
  return useMutation<
    TournamentAnonymusDTO[],
    AxiosError<ApiError>,
    TournamentRecordDAO
  >(
    [SetTournamentKey],
    async (results: TournamentRecordDAO): Promise<TournamentAnonymusDTO[]> => {
      const {data} = await APIRequest.post<TournamentAnonymusDTO[]>(
        `/Integration/SetTournamentResult`,
        results,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      onSuccess: (val, request, context) => {
        queryClient.setQueryData(
          [GetUserTournamentKey, request.tournamentId],
          val,
        );

        if (options?.onSuccess) options.onSuccess(val, request, context);
      },
      ...options,
    },
  );
};

export const useGetDynamicUserTournament = (
  tournamentId: string,
  options?: UseShortMutationOptions<
    TournamentAnonymusDTO[],
    AxiosError<ApiError>,
    DynamicTournamentRequestDTO
  >,
) => {
  return useMutation<
    TournamentAnonymusDTO[],
    AxiosError<ApiError>,
    DynamicTournamentRequestDTO
  >(
    [GetUserTournamentKey, tournamentId],
    async (request) => {
      if (request.userId) {
        const {data} = await APIRequest.post<TournamentAnonymusDTO[]>(
          '/Integration/GetUserTournamentData',
          {...request, tournamentId} as TournamentReqeust,
        );
        return data;
      }

      const {data} = await APIRequest.post<TournamentAnonymusDTO[]>(
        '/Integration/GetTournamentData',
        {...request, tournamentId} as TournamentAnonymusReqeust,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
