import {animated, config, useSpring} from '@react-spring/three';
import {useGLTF} from '@react-three/drei';
import {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {
  ReadyPlayerMeJetpackAnimationBase,
  ReadyPlayerMeJetpackCharacterBaseAnimations,
} from '../../../types/models/v2/animations/JetpackAnimations';
import {DefaultFadeAnimation} from '../../models/animations/AnimationFadePrefabs';
import AnimationLoader, {
  AnimationLoaderRefProps,
} from '../../models/animations/AnimationLoader';
import {
  LoopAnimation,
  RepeatOnceAnimation,
} from '../../models/animations/AnimationRepeatPrefabs';
import ReadyPlayerMeBaseCharacter from '../../models/bases/ReadyPlayerMeBaseCharacter';
import {OnboardingJetpackProps, OnboardingJetpackRef} from './Contract';

const ReadyPlayerMeJetpack = forwardRef<
  OnboardingJetpackRef,
  OnboardingJetpackProps & {
    modelUrl?: string | null;
    modelSex?: string | null;
  }
>(({onLoaded, isDefaultGrown, modelUrl, modelSex}, ref) => {
  const {animations} = useGLTF(
    modelSex === 'female'
      ? '/models/v2/readyPlayerMeModels/RGirlNoAvatar.glb'
      : '/models/v2/readyPlayerMeModels/RManNoAvatar.glb',
  ) as unknown as ReadyPlayerMeJetpackAnimationBase;

  const [isGrown, setIsGrown] = useState(isDefaultGrown);

  const [characterGroup, setCharacterGroup] = useState<THREE.Group>();
  const animatorRef =
    useRef<
      AnimationLoaderRefProps<ReadyPlayerMeJetpackCharacterBaseAnimations>
    >(null);

  const {positionX, positionY, scale} = useSpring({
    positionX: !isGrown ? 0 : 0,
    positionY: !isGrown ? -1.4 : 0,
    scale: !isGrown ? 1.7 : 1.1,
    config: config.stiff,
  });

  useImperativeHandle(ref, () => ({
    grow(isAnimate, duration = 1, playmode = 'once') {
      setIsGrown(true);
      animatorRef.current?.animate('DeSpawn', {
        repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
        duration,
        onFinished: {
          launchBaseAnimation: {
            fadeMode: DefaultFadeAnimation,
          },
        },
      });
    },
    shrink(isAnimate, duration = 1, playmode = 'once') {
      setIsGrown(false);
      animatorRef.current?.animate('Spawn', {
        repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
        duration,
        onFinished: {
          launchBaseAnimation: {
            fadeMode: DefaultFadeAnimation,
          },
        },
      });
    },
    // Bug in animations - right and left are mixed
    pointUpRight(playOnce) {
      animatorRef.current?.animate('PointLeftUp', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointRight(playOnce) {
      animatorRef.current?.animate('PointLeft', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointDownRight(playOnce) {
      animatorRef.current?.animate('PointLeftDown', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },

    pointUpLeft(playOnce) {
      animatorRef.current?.animate('PointRightUp', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointLeft(playOnce) {
      animatorRef.current?.animate('PointRight', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    pointDownLeft(playOnce) {
      animatorRef.current?.animate('PointRightDown', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },

    dance(duration, playmode = 'repeat') {
      animatorRef.current?.animateRandom(['Dance1'], {
        repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
        duration,
        onFinished:
          playmode === 'once'
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
      });
    },
    land(duration, playmode = 'repeat') {
      animatorRef.current?.animate('Idle', {
        repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
        duration,
        onFinished:
          playmode === 'once'
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
      });
    },
    takeoff(duration, playmode = 'once') {
      animatorRef.current?.animate('Fly2', {
        repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
        duration,
        onFinished:
          playmode === 'once'
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
      });
    },
    wave(duration, playmode = 'repeat') {
      animatorRef.current?.animate('Wave', {
        repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
        duration,
        onFinished:
          playmode === 'once'
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
      });
    },

    idle(duration, playmode = 'repeat') {
      animatorRef.current?.animate('Idle', {
        repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
        duration,
        onFinished:
          playmode === 'once'
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
      });
    },
  }));

  return (
    <>
      <pointLight color="#fff" position={[0, 5, 5]} />
      <animated.group
        position-x={positionX}
        position-y={positionY}
        position-z={0}
        scale={scale}
      >
        <ReadyPlayerMeBaseCharacter
          onLoaded={setCharacterGroup}
          scale={[1, 1, 1]}
          modelUrl={
            modelUrl ||
            '/models/v2/readyPlayerMeModels/642efb6825393b8c835630fa.glb'
          }
        />
      </animated.group>
      {characterGroup && (
        <AnimationLoader
          group={characterGroup}
          animations={animations}
          myRef={animatorRef}
          baseAnimation="Idle"
          onLoaded={onLoaded}
        />
      )}
    </>
  );
});

export default ReadyPlayerMeJetpack;
