import {useGLTF} from '@react-three/drei';
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from 'react';
import AnimationLoader, {
  AnimationLoaderRefProps,
} from '../../models/animations/AnimationLoader';
import {OnboardingChestProps, OnboardingChestRef} from './Contract';
import {
  BowBoxChestCharacterAnimations,
  BowBoxChestCharacterBaseModel,
} from '../../../types/models/v2/bases/BowBoxChestCharacterBase';
import ChestCharacterBowBoxModel from '../../models/bases/ChestCharacterBowBoxModel';
import {RepeatOnceAnimation} from '../../models/animations/AnimationRepeatPrefabs';
import {DefaultFadeAnimation} from '../../models/animations/AnimationFadePrefabs';
import {Vector3} from 'three';

const ChestCharacterBowBox = forwardRef<
  OnboardingChestRef,
  OnboardingChestProps
>(({onLoaded, onInnerContentClick, onChestOpened, activeTypeBox}, ref) => {
  const [chestIsOpened, setChestIsOpened] = useState(false);

  const {animations} = useGLTF(
    '/models/v2/fullModels/Box1.glb',
  ) as unknown as BowBoxChestCharacterBaseModel;

  const [characterGroup, setCharacterGroup] = useState<THREE.Group>();
  const animatorRef =
    useRef<AnimationLoaderRefProps<BowBoxChestCharacterAnimations>>(null);

  useImperativeHandle(ref, () => ({
    idle(playOnce) {
      animatorRef.current?.animate('Idle', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    open(playOnce) {
      animatorRef.current?.animate('Open', {
        repeatMode: RepeatOnceAnimation,
      });
      onChestOpened();
    },
    spawn(playOnce) {
      animatorRef.current?.animate('Spawn', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    stop(playOnce) {
      animatorRef.current?.animate('Stop', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
    vanish(playOnce) {
      animatorRef.current?.animate('Vanish', {
        repeatMode: playOnce ? RepeatOnceAnimation : undefined,
        onFinished: playOnce
          ? {
              launchBaseAnimation: {
                fadeMode: DefaultFadeAnimation,
              },
            }
          : undefined,
      });
    },
  }));

  useEffect(() => {
    if (activeTypeBox === 'timer') {
      const timer = setTimeout(() => {
        animatorRef.current?.animate('Open', {
          repeatMode: RepeatOnceAnimation,
        });
        setChestIsOpened(true);
        onChestOpened();
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [activeTypeBox, onChestOpened]);

  return (
    <>
      <ChestCharacterBowBoxModel
        onLoaded={setCharacterGroup}
        onInnerContentClick={onInnerContentClick}
        onBoxClick={() => {
          if (activeTypeBox !== 'click') return;
          if (activeTypeBox === 'click' && !chestIsOpened) {
            animatorRef.current?.animate('Open', {
              repeatMode: RepeatOnceAnimation,
            });
            setChestIsOpened(true);
            onChestOpened();
          }
        }}
        scale={new Vector3(0.00012, 0.00012, 0.00012)}
        position={new Vector3(0, 0, 0)}
      />
      {characterGroup && (
        <AnimationLoader
          group={characterGroup}
          animations={animations}
          myRef={animatorRef}
          baseAnimation="Idle"
          onLoaded={onLoaded}
        />
      )}
    </>
  );
});

export default ChestCharacterBowBox;
