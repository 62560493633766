import {useCallback, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import TournamentTableEventHandler, {
  TournamentTableIntegrationRef,
} from '../../../components/scriptIntegrations/tournamentTable/TournamentTableScriptIntegration';
import TournamentTableUiWrapper from '../../../components/scriptIntegrations/tournamentTable/TournamentTableUiWrapper';
import LeaderboardDexilon from '../../../components/ui/tournamentTable/dexilonStyle/LeaderboardDexilon';

const DexilonTournamentTable: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState(searchParams.get('userId') || undefined);
  const [tournamentId] = useState(searchParams.get('tournamentId'));
  const [rowsToLoad] = useState(Number(searchParams.get('rows')) || 10);

  const tournamentTableCommunicatorRef =
    useRef<TournamentTableIntegrationRef>(null);

  const onLoadByUserIdRequest = useCallback((id: string) => {
    setUserId(id);
  }, []);

  if (!tournamentId) return <div>No tournament id</div>;
  return (
    <>
      <TournamentTableEventHandler
        ref={tournamentTableCommunicatorRef}
        loadByUserId={onLoadByUserIdRequest}
      />
      <TournamentTableUiWrapper
        tournamentId={tournamentId}
        userId={userId}
        rowsToShow={rowsToLoad}
        uiElement={LeaderboardDexilon}
        onLoaded={() => tournamentTableCommunicatorRef.current?.onChildLoaded()}
      />
    </>
  );
};

export default DexilonTournamentTable;
