import {useEffect, useRef, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import SkyboxWithModelEventHandler, {
  SkyboxWithModelIntegrationRef,
} from '../../../components/scriptIntegrations/skyboxWithModeEventHandler/SkyboxWithModeEventHandler';
import SkyboxWithModelAnimations from '../../../components/skyboxWithModel/animations/SkyboxWithModelAnimations';

const SkyboxWithModeWrapper: React.FC = ({}) => {
  const {id} = useParams<{id: string}>();

  const [searchParams] = useSearchParams();
  // const {model, skybox, depth} = useParams<{
  //   model: string;
  //   skybox: string;
  //   depth: string;
  // }>();

  // useEffect(() => {
  //   alert(model);
  // }, []);

  const [localModel, setLocalModel] = useState<string | undefined>(
    searchParams.get('model') ?? undefined,
  );
  const [localSkyboxImageUrl, setLocalSkyboxImageUrl] = useState<string>(
    searchParams.get('skybox') ?? '',
  );
  const [localSkyboxImageDepth, setLocalSkyboxImageDepth] = useState<string>(
    searchParams.get('depth') ?? '',
  );

  const skyboxWithModeWrapperlRef = useRef<SkyboxWithModelIntegrationRef>(null);

  return (
    <>
      <SkyboxWithModelEventHandler
        componentId={id || 'skyboxWithModel'}
        ref={skyboxWithModeWrapperlRef}
        spawnModel={(model) => {
          setLocalModel(model);
        }}
        spawnSkybox={(skybox) => {
          setLocalSkyboxImageUrl(skybox.imageUrl);
          setLocalSkyboxImageDepth(skybox.imageDepth);
        }}
      />

      <SkyboxWithModelAnimations
        gtlf={localModel}
        imageUrl={localSkyboxImageUrl}
        imageDepth={localSkyboxImageDepth}
      />
    </>
  );
};

export default SkyboxWithModeWrapper;
