import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../../services/globalNotification/errorHandler';
import APIRequest from '../../Api';
import {ApiError} from '../../basicTypes/ApiError';
import {ChatRequestDAO} from './dao/ChatRequest';
import {ChatResponseDTO} from './dto/ChatRequest';

export const GetChatgptData = 'gpt/get';

export function useChatgpt(
  request: ChatRequestDAO,
  options?: UseShortQueryOptions<ChatResponseDTO, AxiosError<ApiError>>,
  errorHandler?: (error: AxiosError<ApiError>) => void,
): UseQueryResult<ChatResponseDTO, AxiosError> {
  return useQuery(
    [GetChatgptData, request.queryId],
    async (): Promise<ChatResponseDTO> => {
      const {data} = await APIRequest.post<ChatResponseDTO>(
        '/Chat/GetResponse',
        request,
      );
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
