import React from 'react';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {SliderAnimationVariants} from '../../styles/framerMotionVariants';
import './StemmyYellowButton.scoped.css';

export enum ButtonSize {
  SMALL = 'text-game-sm',
  BASE = 'text-game-lg lg:text-game-base',
  BIG = 'text-game-xl',
}

export interface StemmyYellowButtonProps
  extends React.HTMLProps<HTMLDivElement> {
  answer: string;
  onClick?: () => void;
  btnSize?: ButtonSize;
}

const StemmyYellowButton: React.FC<StemmyYellowButtonProps> = ({
  answer,
  onClick,
  className,
  btnSize = ButtonSize.BASE,
}) => {
  return (
    <motion.button
      initial={'hidden'}
      animate={'visible'}
      transition={{duration: 0.25}}
      variants={SliderAnimationVariants}
      className={classNames(
        className,
        btnSize,
        'px-1 lg:px-3 py-1 text-white font-bold text-center rounded-md cursor-pointer w-20 lg:w-40 flex justify-center items-center font-sigmarOne relative overflow-hidden button-yellow',
      )}
      onClick={onClick}
    >
      <div className="absolute w-full h-full upper-button -top-1/2 opacity-20" />
      <span className="z-10 webkit-box-max-2-line">{answer}</span>
    </motion.button>
  );
};
export default StemmyYellowButton;
