import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

const Achievement: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [title] = useState(searchParams.get('title'));
  const [body] = useState(searchParams.get('body'));

  return (
    <div style={{aspectRatio: '3 / 4'}}>
      <div
        className="w-full bg-no-repeat bg-cover flex flex-col items-stretch"
        style={{
          aspectRatio: '3 / 4',
          padding: '12% 13% 22% 13%',
          backgroundImage: 'url("/acheivement/AcheivementBG.png")',
        }}
      >
        <img
          className="mx-auto block w-1/2"
          style={{margin: '4% auto'}}
          src="/acheivement/Star.png"
        />
        <p
          className="text-game-4xl font-sigmarOne text-center text-white"
          style={{margin: '4%'}}
        >
          {title}
        </p>
        <div className="flex justify-center items-center grow text-game-4xl text-center font-bold text-white">
          <div>{body}</div>
        </div>
      </div>
    </div>
  );
};

export default Achievement;
