import {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import {AnonymousGameSaveDAO} from '../../api/Integration/save/dao/AnonymousGameSaveDAO';
import {AnonymousGameSaveDTO} from '../../api/Integration/save/dto/AnonymousGameSaveDTO';
import {
  LocalSaveGettingEvent,
  SaveAnonymousLoadGettingEvent,
  SaveAnonymousLoadSendingEvent,
  SaveAnonymousSaveGettingEvent,
  SaveCommunicatorSendingEvent,
  SaveGettingEvent,
} from '../../pages/scriptIntegrations/save/SaveTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';

interface SaveIntegrationProps {
  onLoadDataRequest: (platformId?: string) => void;
  onSaveDataRequest: (saveData: AnonymousGameSaveDAO) => void;
  onLocalSaveRequest: (saveData: string) => void;
}

export interface SaveIntegrationRef {
  onDataLoaded: (saveData: AnonymousGameSaveDTO) => void;
  onDataLoadFailed: () => void;
  onDataSaved: () => void;
}

const SaverEventHandler = forwardRef<SaveIntegrationRef, SaveIntegrationProps>(
  ({onLoadDataRequest, onSaveDataRequest, onLocalSaveRequest}, ref) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const eventMessangerRef =
      useRef<
        EventSystemRefProps<
          SaveCommunicatorSendingEvent | SaveAnonymousLoadSendingEvent
        >
      >(null);

    const incomingEventsHandler = useCallback(
      (
        message:
          | SaveGettingEvent
          | SaveAnonymousLoadGettingEvent
          | SaveAnonymousSaveGettingEvent
          | LocalSaveGettingEvent,
      ) => {
        switch (message.event) {
          case 'anonymous-load':
            onLoadDataRequest(
              message.platformId !== '' ? message.platformId : undefined,
            );
            break;

          case 'anonymous-save':
            onSaveDataRequest(message.saveData);
            break;

          case 'local-save':
            onLocalSaveRequest(message.saveData);
            break;

          case 'checkLoaded':
            if (isLoaded) {
              eventMessangerRef.current?.sendMessage({
                event: 'onLoaded',
              });
            }
            break;
        }
      },
      [isLoaded, onLoadDataRequest, onLocalSaveRequest, onSaveDataRequest],
    );

    useImperativeHandle(ref, () => ({
      onDataLoaded(data) {
        eventMessangerRef.current?.sendMessage({
          event: 'anonymous-loaded',
          saveData: data,
        });
      },
      onDataLoadFailed() {
        eventMessangerRef.current?.sendMessage({
          event: 'anonymous-loaded-notfound',
        });
      },
      onDataSaved() {
        eventMessangerRef.current?.sendMessage({
          event: 'onSaved',
        });
      },
    }));

    const OnLoaded = useCallback(() => {
      setIsLoaded(true);
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    }, []);

    return (
      <EventsMessanger<
        | SaveGettingEvent
        | SaveAnonymousLoadGettingEvent
        | SaveAnonymousSaveGettingEvent
        | LocalSaveGettingEvent,
        SaveCommunicatorSendingEvent | SaveAnonymousLoadSendingEvent
      >
        componentName="saver"
        myRef={eventMessangerRef}
        onMessage={incomingEventsHandler}
        onLoaded={OnLoaded}
      />
    );
  },
);

export default SaverEventHandler;
