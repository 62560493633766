import {useChatgpt} from '../api/Integration/gpt/Chatgpt';
import {ChatRequestDAO} from '../api/Integration/gpt/dao/ChatRequest';

const InnerChatGpt: React.FC<
  {request: ChatRequestDAO} & React.HTMLProps<HTMLDivElement>
> = ({request, ...rest}) => {
  const gpt = useChatgpt(request);

  if (!gpt.data) return null;
  return <div {...rest}>{gpt.data.response}</div>;
};

export default InnerChatGpt;
