import GiantDoubleSwitch from '../../../components/giantSwitch/GiantDoubleSwitch';
import TournamentProgressbarUiSimple from '../../../components/ui/tournamentProgressbar/simpleStyle/TournamentProgressbarUiSimple';
import {TournamentProgressbarUiProps} from '../../../components/ui/tournamentProgressbar/Types';
import TournamentTasks from '../../../components/ui/tournamentTasks/simpleStyle/TournamentTasks';
import {TournamentTasksProps} from '../../../components/ui/tournamentTasks/Types';
import DexilonTournamentTable from '../tournamentTable/DexilonTournamentTable';
import SimpleTournamentTable from '../tournamentTable/SimpleTournamentTable';

export type TournamentBundlesTypes = 'simple' | 'dexilon';

interface TournamentBundlesFactoryProps {
  type: TournamentBundlesTypes;
  progressBarProps: TournamentProgressbarUiProps;
  tasksProps: TournamentTasksProps;
}

const TournamentBundleFactory: React.FC<TournamentBundlesFactoryProps> = ({
  type,
  progressBarProps,
  tasksProps,
}) => {
  switch (type) {
    case 'dexilon':
      return (
        <div className="rounded-3xl bg-white">
          <TournamentProgressbarUiSimple {...progressBarProps}>
            <GiantDoubleSwitch
              leftTitle="Tasks"
              rigthTitle="Leaderboard"
              leftChild={
                <div className="shadow-whole pt-2 px-2 rounded-3xl h-full">
                  <TournamentTasks {...tasksProps} />
                </div>
              }
              rightChild={
                <div className="shadow-whole pt-2 px-2 rounded-3xl h-full flex justify-center">
                  <DexilonTournamentTable />
                </div>
              }
              mainColor={tasksProps.mainColor}
            />
          </TournamentProgressbarUiSimple>
        </div>
      );
    case 'simple':
      return (
        <div className="rounded-3xl bg-white">
          <TournamentProgressbarUiSimple {...progressBarProps}>
            <GiantDoubleSwitch
              leftTitle="Tasks"
              rigthTitle="Leaderboard"
              leftChild={
                <div className="shadow-whole pt-2 px-2 rounded-3xl h-full">
                  <TournamentTasks {...tasksProps} />
                </div>
              }
              rightChild={
                <div className="shadow-whole pt-2 px-2 rounded-3xl h-full flex justify-center">
                  <SimpleTournamentTable />
                </div>
              }
              mainColor={tasksProps.mainColor}
            />
          </TournamentProgressbarUiSimple>
        </div>
      );
  }
};

export default TournamentBundleFactory;
