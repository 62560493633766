import {AnimatePresence, motion} from 'framer-motion';
import {InformingCardProps, TaskCardProps} from '../../ui/informingCard/Types';
import FlyingCardFactory, {
  FlyingCardTypes,
} from '../../../pages/scriptIntegrations/flyingCards/FlyingCardsFactory';
import {useCallback, useEffect, useState} from 'react';

export interface FlyingCardsAnimationsProps {
  flyingCardsData: InformingCardProps[] | TaskCardProps[];
  typeCard: FlyingCardTypes | undefined;
  coverCard: string;
  cardOpenedById: (id: string) => void;
}

const FlyingCardsAnimations: React.FC<FlyingCardsAnimationsProps> = ({
  flyingCardsData,
  typeCard,
  coverCard,
  cardOpenedById,
}) => {
  const [showCards, setShowCards] = useState(true);
  const [isCoverlocalCards, setIsCoverLocalCards] = useState<boolean[]>([]); // store data cards, that show the cover sheet

  const clickOnCoverCard = useCallback(
    (index: number) => {
      const newLocalCards = [...isCoverlocalCards];
      newLocalCards[index] = false;
      setIsCoverLocalCards(newLocalCards);
    },
    [isCoverlocalCards],
  );

  const clickOnFaceAnyCardAfterAllOpened = useCallback(() => {
    if (isCoverlocalCards.filter((card) => card).length === 0) {
      setIsCoverLocalCards(isCoverlocalCards.map(() => true));
      setShowCards(false);
    }
  }, [isCoverlocalCards]);

  useEffect(() => {
    setIsCoverLocalCards(new Array(flyingCardsData.length).fill(true));
  }, [flyingCardsData]);

  return (
    <>
      <AnimatePresence>
        {showCards && (
          <div className="absolute w-full h-full flex justify-center items-center top-0 left-0 z-50 overflow-hidden">
            <div className="absolute w-full h-full top-0 left-0 bg-[#202020] opacity-90 z-10" />

            <div className="flex flex-nowrap w-full justify-evenly items-center gap-[2%] z-10">
              {flyingCardsData.map((card, index) => {
                return (
                  <motion.div
                    key={index}
                    className="aspect-[92/136] w-1/5"
                    animate={{
                      x: ['100vw', '0vw'],
                      y: ['-100vh', '0vh'],
                      scale: [0, 0.5, 1],
                    }}
                    initial={{
                      x: '100vw',
                      y: '-100vh',
                      scale: 0,
                    }}
                    exit={{
                      scale: 0,
                      opacity: [1, 0],
                      transition: {duration: 0.1},
                    }}
                    transition={{
                      duration: 0.3,
                      ease: 'easeOut',
                      delay: index / 10,
                    }}
                  >
                    <AnimatePresence>
                      {isCoverlocalCards[index] && (
                        <motion.div
                          className="rounded-3xl border-[1.5px] border-solid border-[rgb(110,110,110)] infoCard1 w-full h-full overflow-hidden flex justify-center items-center cursor-pointer absolute"
                          onClick={() => {
                            clickOnCoverCard(index);
                            cardOpenedById(card.id);
                          }}
                          exit={{
                            scale: [1, 1.3, 1.25, 1.25, 1.25, 1.25, 1.25],
                            rotateY: [0, 0, 0, 0, 0, 0, -90],
                            transition: {duration: 0.4},
                          }}
                          initial={{scale: 1}}
                        >
                          <img
                            src={coverCard}
                            alt="Cover card"
                            className="w-full h-full top-0 left-0"
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                    {!isCoverlocalCards[index] && (
                      <motion.div
                        className="w-full h-full"
                        onClick={clickOnFaceAnyCardAfterAllOpened}
                        animate={{
                          rotateY: [-90, 0],
                          transition: {delay: 0.4, duration: 0.1},
                        }}
                        initial={{
                          scale: 1.25,
                        }}
                      >
                        <FlyingCardFactory
                          type={typeCard || 'info'}
                          cardProps={{
                            image: card.image,
                            text: card.text,
                            title: card.title,
                            id: card.id,
                          }}
                        />
                      </motion.div>
                    )}
                  </motion.div>
                );
              })}
            </div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FlyingCardsAnimations;
