import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import classNames from 'classnames';
import {IceBreakerUiProps} from '../Types';
import {SliderAnimationVariants} from '../../../styles/framerMotionVariants';
import SmallIceBreaker from './SmallIceBreak';
import './IceBreakerUiSimple.scoped.css';

const IceBreakerUiSimple: React.FC<IceBreakerUiProps> = ({
  numberClicks,
  onBreaked,
  onLoaded = () => {},
}) => {
  const [currentIceClicked, setCurrentOnIceClicked] = useState(0);
  const [isShowIce, setIsShowIce] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (currentIceClicked === numberClicks) {
      timer = setTimeout(() => {
        setIsShowIce(true);
      }, 600);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [currentIceClicked, numberClicks]);

  useEffect(() => {
    onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence>
      {isShowIce === false && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{duration: 0.25}}
          variants={SliderAnimationVariants}
          className={classNames(
            'w-full h-full relative aspect-video cursor-pointer',
            currentIceClicked === 0 && 'overflow-hidden',
          )}
          onClick={() => {
            setCurrentOnIceClicked((old) => {
              if (old >= numberClicks) return old;
              return old + 1;
            });
            if (numberClicks === currentIceClicked + 1 && onBreaked) {
              onBreaked();
            }
          }}
        >
          <img
            src="/assets/iceBreak/full-ice.png"
            className={classNames(
              'absolute w-full h-full top-0 left-0',
              currentIceClicked !== 0 && 'hidden',
            )}
          />

          <SmallIceBreaker
            className="absolute w-[33.5%] top-0 left-0"
            img="/assets/iceBreak/ice-01.png"
            xMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '-20%'
                : '-5%'
            }
            yMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '-10%'
                : '-2%'
            }
          />

          <SmallIceBreaker
            className="absolute w-[28.8%] top-0 left-[14.7%]"
            img="/assets/iceBreak/ice-02.png"
            xMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '-5%'
                : '-1%'
            }
            yMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '-25%'
                : '-2%'
            }
          />

          <SmallIceBreaker
            className="absolute w-[31.7%] top-0 left-[28.7%]"
            img="/assets/iceBreak/ice-03.png"
            xMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '20%'
                : '2%'
            }
            yMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '-25%'
                : '-2%'
            }
          />

          <SmallIceBreaker
            className="absolute top-0 right-0 w-[59.5%]"
            img="/assets/iceBreak/ice-04.png"
            xMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '20%'
                : '2%'
            }
            yMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '-20%'
                : '-2%'
            }
          />

          <SmallIceBreaker
            className="absolute top-0 right-[-0.6%] w-[60%]"
            img="/assets/iceBreak/ice-05.png"
            xMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '25%'
                : '2%'
            }
            yMoved="0%"
          />

          <SmallIceBreaker
            className="absolute bottom-[-3%] right-[-0.7%] w-[68%]"
            img="/assets/iceBreak/ice-06.png"
            xMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '23%'
                : '2%'
            }
            yMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '15%'
                : '2%'
            }
          />

          <SmallIceBreaker
            className="absolute bottom-[-0.7%] left-[1.3%] w-[98%]"
            img="/assets/iceBreak/ice-07.png"
            xMoved="0%"
            yMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '21%'
                : '2%'
            }
          />

          <SmallIceBreaker
            className="absolute left-[-0.7%] bottom-[-1%] w-[34.8%]"
            img="/assets/iceBreak/ice-08.png"
            xMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '-20%'
                : '-2%'
            }
            yMoved={
              currentIceClicked === 0
                ? '0%'
                : currentIceClicked === numberClicks
                ? '7%'
                : '2%'
            }
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default IceBreakerUiSimple;
