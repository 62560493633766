import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import {ReadyPlayerMeBase} from '../../../types/models/v2/readyPlayerMe/ReadyPlayerMeBase';
import {DefaultModel} from '../types/basic';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ReadyPlayerMeBaseCharacterProps extends DefaultModel {
  modelUrl: string;
}

const ReadyPlayerMeBaseCharacter: React.FC<ReadyPlayerMeBaseCharacterProps> = ({
  onLoaded,
  position,
  rotation,
  scale,
  modelUrl,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials} = useGLTF(modelUrl) as unknown as ReadyPlayerMeBase;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group position={position} scale={scale} rotation={rotation}>
      <group ref={group} dispose={null} receiveShadow castShadow>
        <primitive object={nodes.Hips} />
        {/* {nodes.Wolf3D_Avatar && (
          <skinnedMesh
            name="Wolf3D_Avatar"
            geometry={nodes.Wolf3D_Avatar.geometry}
            material={materials.Wolf3D_Avatar}
            skeleton={nodes.Wolf3D_Avatar.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Avatar.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Avatar.morphTargetInfluences}
          />
        )} */}
        {nodes.Wolf3D_Body && (
          <skinnedMesh
            geometry={nodes.Wolf3D_Body.geometry}
            material={materials.Wolf3D_Body}
            skeleton={nodes.Wolf3D_Body.skeleton}
          />
        )}
        {nodes.Wolf3D_Outfit_Bottom && (
          <skinnedMesh
            geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
            material={materials.Wolf3D_Outfit_Bottom}
            skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
          />
        )}
        {nodes.Wolf3D_Outfit_Footwear && (
          <skinnedMesh
            geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
            material={materials.Wolf3D_Outfit_Footwear}
            skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
          />
        )}
        {nodes.Wolf3D_Outfit_Top && (
          <skinnedMesh
            geometry={nodes.Wolf3D_Outfit_Top.geometry}
            material={materials.Wolf3D_Outfit_Top}
            skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
          />
        )}
        {nodes.Wolf3D_Hair && (
          <skinnedMesh
            geometry={nodes.Wolf3D_Hair.geometry}
            material={materials.Wolf3D_Hair}
            skeleton={nodes.Wolf3D_Hair.skeleton}
          />
        )}
        {/* {nodes.Wolf3D_Glasses && (
          <skinnedMesh
            geometry={nodes.Wolf3D_Glasses.geometry}
            material={materials.Wolf3D_Glasses}
            skeleton={nodes.Wolf3D_Glasses.skeleton}
          />
        )} */}
        {nodes.EyeLeft && (
          <skinnedMesh
            name="EyeLeft"
            geometry={nodes.EyeLeft.geometry}
            material={materials.Wolf3D_Eye}
            skeleton={nodes.EyeLeft.skeleton}
            morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
          />
        )}
        {nodes.EyeRight && (
          <skinnedMesh
            name="EyeRight"
            geometry={nodes.EyeRight.geometry}
            material={materials.Wolf3D_Eye}
            skeleton={nodes.EyeRight.skeleton}
            morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
            morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
          />
        )}
        {nodes.Wolf3D_Head && (
          <skinnedMesh
            name="Wolf3D_Head"
            geometry={nodes.Wolf3D_Head.geometry}
            material={materials.Wolf3D_Skin}
            skeleton={nodes.Wolf3D_Head.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
          />
        )}
        {nodes.Wolf3D_Teeth && (
          <skinnedMesh
            name="Wolf3D_Teeth"
            geometry={nodes.Wolf3D_Teeth.geometry}
            material={materials.Wolf3D_Teeth}
            skeleton={nodes.Wolf3D_Teeth.skeleton}
            morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
            morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
          />
        )}
      </group>
    </group>
  );
};

export default ReadyPlayerMeBaseCharacter;
