import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import {DefaultModel} from '../types/basic';
import {RedBoxChestCharacterBaseModel} from '../../../types/models/v2/bases/RedBoxChestCharacterBase';

interface ChestCharacterRedBoxModelProps extends DefaultModel {
  onInnerContentClick: () => void;
  onBoxClick: () => void;
}

const ChestCharacterRedBoxModel: React.FC<ChestCharacterRedBoxModelProps> = ({
  onLoaded,
  onInnerContentClick,
  onBoxClick,
  position,
  rotation,
  scale,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials} = useGLTF(
    '/models/v2/fullModels/Box2.glb',
  ) as unknown as RedBoxChestCharacterBaseModel;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group
      ref={group}
      dispose={null}
      position={position}
      scale={scale}
      rotation={rotation}
      receiveShadow
      castShadow
    >
      <group name="Scene">
        <group name="BoxBones" rotation={[Math.PI / 2, 0, 0]}>
          <primitive object={nodes.simple_gift_base_joint} />
          <primitive object={nodes.simple_gift_cap_joint} />
        </group>
        <skinnedMesh
          name="Glow"
          geometry={nodes.Glow.geometry}
          material={materials.Glow}
          skeleton={nodes.Glow.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <skinnedMesh
          name="Box"
          geometry={nodes.Box.geometry}
          material={materials.Box}
          skeleton={nodes.Box.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          onClick={onBoxClick}
        />
      </group>
    </group>
  );
};

export default ChestCharacterRedBoxModel;
