import classNames from 'classnames';
import {useEffect} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {useResizeDetector} from 'react-resize-detector';
import {DialogBaseProps, DialogStyle} from '../Contract';
import {DialogMapProps} from '../MapPluginContract';
import SimpleDialogButton from './SimpleButton';

interface SimpleMapDialogProps {
  props: DialogMapProps;
  base: DialogBaseProps;
  style: DialogStyle;
}

const SimpleMapDialog: React.FC<SimpleMapDialogProps> = ({
  props: {data, onButtonPressed},
  base: {onCloseButtonPressed, onHeightChanged, onWidthChanged},
  style,
}) => {
  const {width, height, ref} = useResizeDetector({
    refreshRate: 200,
  });

  useEffect(() => {
    if (!height || !onHeightChanged) return;
    onHeightChanged(height);
  }, [height, onHeightChanged]);

  useEffect(() => {
    if (!width || !onWidthChanged) return;
    onWidthChanged(width);
  }, [onWidthChanged, width]);

  if (!data) return null;
  return (
    <div ref={ref}>
      <div
        className="relative rounded-lg cursor-default h-fit px-5 pb-2 pt-1"
        style={{
          backgroundColor: style.backgroundColor,
          color: style.mainTextColor,
          border: style.borderColor && `1px solid ${style.borderColor}`,
        }}
      >
        <div className="flex border-b border-solid-black/80 py-1">
          {data.title && <div className="text-sm ">{data.title}</div>}
          <div
            className="ml-auto text-[#C4C5C7] hover:text-black cursor-pointer"
            onClick={onCloseButtonPressed}
          >
            <AiOutlineClose />
          </div>
        </div>

        <div className="space-y-2 my-2">
          <div>{data.text}</div>
          <div>
            <iframe
              className="border-none w-full"
              src={`https://www.google.com/maps/embed?${data.mapQuery}`}
              loading="lazy"
            ></iframe>
          </div>
        </div>
        {data.innerButtons && (
          <div className="flex gap-2">
            {data.innerButtons.map((x) => (
              <SimpleDialogButton
                key={x.id}
                className={classNames(x.flex, 'ml-auto')}
                onClick={() => onButtonPressed(x.id)}
                style={x.style}
                title={x.title}
                {...style}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleMapDialog;
