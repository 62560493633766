import {useCallback} from 'react';
import {TournamentProgressbarUiProps} from '../../ui/tournamentProgressbar/Types';

interface TournamentProgressbarUiWrapperProps {
  userId?: string;
  uiElement: React.FC<TournamentProgressbarUiProps>;

  onLoaded: () => void;
  onTaskCompleted: (id: string) => void;
  onTaskCollected: (id: string) => void;
}

const TournamentProgressbarDataHandler: React.FC<
  TournamentProgressbarUiWrapperProps
> = ({uiElement, onLoaded, onTaskCompleted, onTaskCollected}) => {
  const getContent = useCallback(() => {
    return uiElement({
      onLoaded,
      onTaskCompleted,
      onTaskCollected,
      mainColor: 'blue',
      oppositeColor: 'white',
      progressbar: {
        id: 'asd9235ht',
        completed: 130,
        total: 700,
        stepForMarks: 50,
        prizes: [
          {
            id: 'prize01',
            img: '/assets/tournamentProgressbar/gift.png',
            textButton: 'Text Button',
            title: 'Title safsdf3',
            points: 69,
            onButtonClick: () => {
              console.log('prize01 clicked');
            },
          },
          {
            id: 'prize02',
            img: '/assets/tournamentProgressbar/gift.png',
            textButton: 'Text Button',
            title: 'Title fghhfdfh',
            points: 150,
          },
          {
            id: 'prize03',
            img: '/assets/tournamentProgressbar/gift.png',
            textButton: 'What is inside??',
            title: 'Title 234srg',
            points: 250,
            onButtonClick: () => {
              console.log('prize03 clicked');
            },
          },
          {
            id: 'prize04',
            img: '/assets/tournamentProgressbar/gift.png',
            textButton: 'What is inside??',
            title: 'Title 56dggdf',
            points: 370,
          },
          {
            id: 'prize05',
            img: '/assets/tournamentProgressbar/gift.png',
            textButton: 'What is inside??',
            title: 'Title dhf5435dg',
            points: 550,
          },
          {
            id: 'prize06',
            img: '/assets/tournamentProgressbar/gift.png',
            textButton: 'What is inside??',
            title: 'Title ghfjjhhg',
            points: 625,
          },
        ],
      },
    });
  }, [uiElement]);

  return <>{getContent()}</>;
};

export default TournamentProgressbarDataHandler;
