import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {DialogFormsInput} from '../../ui/dialog/FormsPluginContract';
import {DataFieldCommonProps} from '../DataFieldFactory';
import './Input.scoped.css';

interface InputDataFieldProps extends DataFieldCommonProps {
  data: DialogFormsInput;
}

const InputDataField: React.FC<InputDataFieldProps> = ({
  data: {id, title, defaultValue, type, isRequired, regexRule},
  containerProps: {className, ...rest} = {},
  onDataUpdated,
}) => {
  const [isValid, setIsValid] = useState(true);

  const [localValue, setLocalValue] = useState(defaultValue || '');
  useEffect(() => {
    setLocalValue(defaultValue || '');
  }, [defaultValue]);

  const handleChange = useCallback(
    (val: string) => {
      setLocalValue(val);

      if ((isRequired && val === '') || (regexRule && !val.match(regexRule))) {
        setIsValid(false);
        onDataUpdated(false);
        return;
      }

      setIsValid(true);
      onDataUpdated(true, {id, value: val});
    },
    [id, isRequired, onDataUpdated, regexRule],
  );

  return (
    <div className={classNames('relative', className)} {...rest}>
      <input
        id={id}
        type={type}
        className={classNames(
          'h-full w-full px-2 transition-all rounded-sm text-sm',
          !isValid ? 'border-red-300' : 'border-gray-300',
          'focus:border-ffocusoutline',
        )}
        value={localValue}
        onChange={(event) => handleChange(event.target.value)}
      />
      <label
        htmlFor={id}
        className="absolute left-2 transition-all bg-white px-1"
      >
        {title}
        {isRequired && <span className="pl-1 text-pink">*</span>}
      </label>
    </div>
  );
};

export default InputDataField;
