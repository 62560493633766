import {InformingCardProps} from '../ui/informingCard/Types';
import infoCardBg1 from '../../assets/flyingCards/infoCardBg1.png';
import FlyingCardHeader from '../flyingCards/flyingCardsHeader/FlyingCardHeader';
import FlyingCardParagraph from '../flyingCards/flyingCardsParagraph/FlyingCardParagraph';
import './InformingCard.css';

const InformingCard: React.FC<InformingCardProps> = ({
  title,
  text,
  image,
  id,
}) => {
  return (
    <div
      id={id}
      className="rounded-3xl border-[1.5px] border-solid border-[rgb(110,110,110)] infoCard1 w-full h-full relative overflow-hidden flex justify-center items-center cursor-pointer"
    >
      <img
        src={infoCardBg1}
        alt="Informing Card"
        className="w-full h-full absolute left-0 top-0"
      />
      <div className="absolute w-[200%] aspect-square bg-[#514469] rounded-full blur-[70px]" />
      <div className="absolute bottom-[29%]">
        <svg
          width="160"
          height="28"
          viewBox="0 0 160 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            opacity="0.26"
            cx="80.1167"
            cy="12.15318"
            rx="80.4571"
            ry="12.08842"
            fill="url(#paint0_radial_2694_1045)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_2694_1045"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(80.1167 12.15318) rotate(90) scale(12.08842 80.4571)"
            >
              <stop />
              <stop offset="1" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <img
        src={image}
        alt="Informing Card"
        className="w-4/5 left-[10%] top-[14%] absolute"
      />
      <div className="absolute w-full bottom-[12%] z-10 flex flex-col gap-3">
        <FlyingCardHeader text={title} />
        <FlyingCardParagraph text={text} />
      </div>
    </div>
  );
};
export default InformingCard;
