import React, {useEffect, useRef, useState, useCallback} from 'react';
import {motion} from 'framer-motion';
import classNames from 'classnames';
import {TournamentProgressbarUiProps} from '../Types';
import ScrollContainer from 'react-indiana-drag-scroll';
import './TournamentProgressbarUiSimple.scoped.css';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import {BasicElement} from '../../../../helpers/BasicElement';
import SimpleButton from '../../../form/button/SimpleButton';

const TournamentProgressbarUiSimple: React.FC<
  TournamentProgressbarUiProps & BasicElement
> = ({
  progressbar: tournamentProgressbar,

  mainColor,
  oppositeColor,

  onLoaded,
  //onTaskCollected,
  //onTaskCompleted,
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const progressBar = useRef<any>(null);

  const [scrollLeftMax, setScrollLeftMax] = useState(0);

  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    setScrollLeftMax(progressBar.current.scrollLeftMax);
    setScrollLeft(progressBar.current.scrollLeft);
    onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLeftArrow = useCallback(() => {
    if (scrollLeftMax === 0) return;
    progressBar.current.scrollLeft -= 250;
  }, [scrollLeftMax]);

  const onRightArrow = useCallback(() => {
    if (scrollLeftMax === 0) return;
    progressBar.current.scrollLeft += 250;
  }, [scrollLeftMax]);

  // ф-ия проверяет есть ли рядом с обычной разметкой (с заданным шагом = tournamentProgressbar.stepForMarks) призовая разметка. Призовые полоски чуть больше, а их цифры темнее, и чтобы призовая разметка не накладывалась на обычную, мы скрываем обычную, если рядом есть призовая. Считаем что разметки находятся достаточно близко друг к другу, если расстояние между ними меньше четверти от шага обычной разметки.
  const checkNearPrize = useCallback(
    (step: number) => {
      let isShow = true;
      tournamentProgressbar.prizes.forEach((prize) => {
        if (
          Math.abs(prize.points - step) <
          tournamentProgressbar.stepForMarks / 4
        ) {
          isShow = false;
        }
      });
      return isShow;
    },
    [tournamentProgressbar.prizes, tournamentProgressbar.stepForMarks],
  );

  return (
    <>
      <div className="flex gap-2">
        {scrollLeftMax !== 0 && (
          <div className="pb-[5%] w-12 top-0 hidden md:flex items-end">
            <div
              className={classNames(
                'w-12 aspect-square rounded-full font-beVietnamPro text-white flex justify-center items-center font-extrabold opacity-90 z-10',
                scrollLeft < 2 ? 'pointer-events-none' : 'cursor-pointer',
              )}
              style={{background: scrollLeft < 2 ? '#E9E9E9' : mainColor}}
              onClick={onLeftArrow}
            >
              <BsChevronLeft />
            </div>
          </div>
        )}
        <ScrollContainer
          innerRef={progressBar}
          className="relative h-full pt-[385px] pb-[97px] whitespace-nowrap scroll-smooth cursor-pointer transition duration-300"
          draggingClassName="scale-y-105"
          onScroll={() => {
            setScrollLeft(progressBar.current.scrollLeft);
            setScrollLeftMax(progressBar.current.scrollLeftMax);
          }}
        >
          <div className="relative h-12 flex items-center bg-[#8E8E924D] w-fit rounded-full">
            <motion.div
              className={
                'h-full w-full absolute rounded-full flex justify-end min-w-fit'
              }
              style={{background: `${mainColor}4D`}}
              transition={{duration: 0.5, ease: 'backInOut'}}
              initial={{width: 0}}
              animate={{
                width: `${
                  (tournamentProgressbar.completed /
                    tournamentProgressbar.total) *
                  100
                }%`,
              }}
            >
              <div
                className="h-full aspect-square rounded-full font-beVietnamPro text-white flex justify-center items-center font-extrabold text-md opacity-90 z-10"
                style={{background: mainColor}}
              >
                {tournamentProgressbar.completed}
              </div>
            </motion.div>
            {new Array(
              Math.floor(
                tournamentProgressbar.total /
                  tournamentProgressbar.stepForMarks,
              ),
            )
              .fill('')
              .map((step, index) => {
                return (
                  <div
                    key={index}
                    className="min-w-[150px] h-full relative flex items-center"
                  >
                    {index !== 0 && (
                      <div
                        className="absolute h-[28px] border-2 border-solid rounded-full flex justify-center opacity-50"
                        style={{
                          borderColor:
                            index * tournamentProgressbar.stepForMarks <
                            tournamentProgressbar.completed
                              ? oppositeColor
                              : '#8E8E92',
                        }}
                      >
                        {checkNearPrize(
                          index * tournamentProgressbar.stepForMarks,
                        ) && (
                          <div className="absolute top-[150%] font-beVietnamPro opacity-50 text-[#0B0B0B] text-lg">
                            {index * tournamentProgressbar.stepForMarks}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            {tournamentProgressbar.prizes.map((prize) => {
              return (
                <div
                  key={prize.id}
                  className="absolute h-[37px] border-2 border-solid rounded-full flex justify-center"
                  style={{
                    left: `${
                      (prize.points / tournamentProgressbar.total) * 100
                    }%`,
                    borderColor:
                      prize.points < tournamentProgressbar.completed
                        ? oppositeColor
                        : '#8E8E92',
                  }}
                >
                  <div
                    className="absolute top-[-285px] w-[210px] aspect-[1/1.2] rounded-[18px]"
                    style={{
                      backgroundColor: `${mainColor}4D`,
                    }}
                  >
                    <div className="px-[5%] pb-[7%] flex flex-col h-full justify-end">
                      {prize.img && (
                        <img
                          src={prize.img}
                          alt="Prize gift"
                          className="object-contain object-center w-full absolute left-0 top-[-42px]"
                        />
                      )}
                      {/* {prize.title && (
                        <p className="font-beVietnamPro text-[#0B0B0B] text-game-base sm:text-game-sm lg:text-game-xs whitespace-nowrap overflow-hidden">
                          {prize.title}
                        </p>
                      )} */}
                      <SimpleButton
                        bgColor={mainColor}
                        textColor={oppositeColor}
                        className="font-semibold text-xl"
                      >
                        {prize.textButton}
                      </SimpleButton>
                    </div>
                  </div>
                  <div className="absolute top-[42px] font-beVietnamPro opacity-90 text-[#0B0B0B] text-lg">
                    {prize.points}
                  </div>
                </div>
              );
            })}
          </div>
        </ScrollContainer>
        {scrollLeftMax !== 0 && (
          <div className="pb-[5%] w-12 top-0 hidden md:flex items-end">
            <div
              className={classNames(
                'w-12 aspect-square rounded-full font-beVietnamPro text-white flex justify-center items-center font-extrabold opacity-90 z-10',
                scrollLeft === scrollLeftMax
                  ? 'pointer-events-none'
                  : 'cursor-pointer',
              )}
              style={{
                background:
                  scrollLeft === scrollLeftMax ? '#E9E9E9' : mainColor,
              }}
              onClick={onRightArrow}
            >
              <BsChevronRight />
            </div>
          </div>
        )}
      </div>
      <div className="md:px-12">{children}</div>
    </>
  );
};
export default TournamentProgressbarUiSimple;
