import InformingCard from '../../../components/informingCard/InformingCard';
import {InformingCardProps} from '../../../components/ui/informingCard/Types';

export type FlyingCardTypes = 'info' | 'task';

interface FlyingCardFactoryProps {
  type: FlyingCardTypes;
  cardProps: InformingCardProps;
}

const FlyingCardFactory: React.FC<FlyingCardFactoryProps> = ({
  type,
  cardProps,
}) => {
  switch (type) {
    case 'info':
      return <InformingCard {...cardProps} />;
    case 'task':
      return <div></div>;
  }
};

export default FlyingCardFactory;
