import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {BasicElement} from '../../helpers/BasicElement';
import EventsMessanger, {
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';
import {DialogData, DialogStyle} from '../ui/dialog/Contract';
import {
  DataFieldResponse,
  DialogFormsData,
} from '../ui/dialog/FormsPluginContract';
import {DialogGptData} from '../ui/dialog/GptPluginContract';
import {DialogMapData} from '../ui/dialog/MapPluginContract';

interface DialogGettingBasicEvent {
  event: 'clear';
}

interface DialogGettingSetupEvent {
  event: 'setup';
  payload: DialogData;
}

interface DialogGptSetupEvent {
  event: 'gptSetup';
  payload: DialogGptData;
}

interface DialogFormsSetupEvent {
  event: 'formSetup';
  payload: DialogFormsData;
}

interface DialogMapSetupEvent {
  event: 'mapSetup';
  payload: DialogMapData;
}

interface DialogGettingStyleEvent {
  event: 'setStyle';
  payload: DialogStyle;
}

interface DialogSendingBasicEvent {
  event: 'onLoaded' | 'onCloseButtonClick';
}

interface DialogSendingSizeEvent {
  event: 'widthChanged' | 'heightChanged';
  payload: number;
}

interface DialogSendingButtonEvent {
  event: 'buttonClicked';
  payload: {
    buttonId: string;
  };
}

interface DialogFormApplyEvent {
  event: 'formCompleted';
  payload: {
    data: DataFieldResponse[];
  };
}

export interface DialogScriptIntegrationRef {
  onHeightChanged: (val: number) => void;
  onWidthChanged: (val: number) => void;

  onButtonPressed: (id: string) => void;
  onCloseButtonPressed: () => void;
}

export interface DialogFormsPluginRef {
  onFormApply: (data: DataFieldResponse[]) => void;
}

interface DialogScriptIntegrationProps extends BasicElement {
  componentId: string;

  clearDialog: () => void;
  updateDialog: (data: DialogData) => void;
  updateStyle: (data: DialogStyle) => void;
}

interface DialogFormsPluginProps {
  updateDialogForms: (data: DialogFormsData) => void;
}

interface DialogGptPluginProps {
  updateDialogGpt: (data: DialogGptData) => void;
}

interface DialogMapPluginProps {
  updateDialogMap: (data: DialogMapData) => void;
}

const DialogScriptIntegration = forwardRef<
  DialogScriptIntegrationRef & DialogFormsPluginRef,
  DialogScriptIntegrationProps &
    DialogFormsPluginProps &
    DialogGptPluginProps &
    DialogMapPluginProps
>(
  (
    {
      componentId,
      clearDialog,
      updateDialog,
      updateStyle,
      updateDialogForms,
      updateDialogGpt,
      updateDialogMap,
      children,
    },
    ref,
  ) => {
    const eventMessangerRef =
      useRef<
        EventSystemRefProps<
          | DialogSendingBasicEvent
          | DialogSendingSizeEvent
          | DialogSendingButtonEvent
          | DialogFormApplyEvent
        >
      >(null);

    const incomingEventsHandler = useCallback(
      (
        message:
          | DialogGettingBasicEvent
          | DialogGettingSetupEvent
          | DialogGptSetupEvent
          | DialogMapSetupEvent
          | DialogFormsSetupEvent
          | DialogGettingStyleEvent,
      ) => {
        switch (message.event) {
          case 'clear':
            clearDialog();
            return;
          case 'setStyle':
            updateStyle(message.payload);
            return;
          case 'setup':
            updateDialog(message.payload);
            return;
          case 'formSetup':
            updateDialogForms(message.payload);
            return;
          case 'gptSetup':
            updateDialogGpt(message.payload);
            return;
          case 'mapSetup':
            updateDialogMap(message.payload);
        }
      },
      [
        clearDialog,
        updateDialog,
        updateDialogForms,
        updateDialogGpt,
        updateDialogMap,
        updateStyle,
      ],
    );

    useImperativeHandle(ref, () => ({
      onHeightChanged(val) {
        eventMessangerRef.current?.sendMessage({
          event: 'heightChanged',
          payload: val,
        });
      },
      onWidthChanged(val) {
        eventMessangerRef.current?.sendMessage({
          event: 'widthChanged',
          payload: val,
        });
      },
      onButtonPressed(id) {
        eventMessangerRef.current?.sendMessage({
          event: 'buttonClicked',
          payload: {
            buttonId: id,
          },
        });
      },
      onCloseButtonPressed() {
        eventMessangerRef.current?.sendMessage({
          event: 'onCloseButtonClick',
        });
      },
      onFormApply(data) {
        eventMessangerRef.current?.sendMessage({
          event: 'formCompleted',
          payload: {
            data,
          },
        });
      },
    }));

    const [, setComponentsReady] = useState({
      messanger: false,
      master: false,
    });

    const onLoaded = useCallback((source: 'messanger' | 'master') => {
      setComponentsReady((old) => {
        const updated = {...old, [source]: true};

        if (updated.master && updated.messanger) {
          eventMessangerRef.current?.sendMessage({
            event: 'onLoaded',
          });
        }
        return updated;
      });
    }, []);

    useEffect(() => {
      onLoaded('master');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div>
        <EventsMessanger<
          | DialogGettingBasicEvent
          | DialogGettingSetupEvent
          | DialogGptSetupEvent
          | DialogMapSetupEvent
          | DialogFormsSetupEvent
          | DialogGettingStyleEvent,
          | DialogSendingBasicEvent
          | DialogSendingSizeEvent
          | DialogSendingButtonEvent
          | DialogFormApplyEvent
        >
          componentName={componentId}
          myRef={eventMessangerRef}
          onMessage={incomingEventsHandler}
          onLoaded={() => onLoaded('messanger')}
        />
        {children}
      </div>
    );
  },
);

export default DialogScriptIntegration;
