import {
  DialogBaseProps,
  DialogProps,
  DialogStyle,
} from '../../../components/ui/dialog/Contract';
import {DialogFormProps} from '../../../components/ui/dialog/FormsPluginContract';
import {DialogGptProps} from '../../../components/ui/dialog/GptPluginContract';
import {DialogMapProps} from '../../../components/ui/dialog/MapPluginContract';
import SimpleDialog from '../../../components/ui/dialog/simple/SimpleDialog';
import SimpleFormDialog from '../../../components/ui/dialog/simple/SimpleFormDialog';
import SimpleGptDialog from '../../../components/ui/dialog/simple/SimpleGptDialog';
import SimpleMapDialog from '../../../components/ui/dialog/simple/SimpleMapDialog';
import {DialogPlugin} from './DialogWrapper';

export type DialogType = 'simple';

interface DialogStyleFactoryProps {
  type: DialogType;
  style: DialogStyle;
  plugin: DialogPlugin;

  baseProps: DialogBaseProps;

  defaultProperties: DialogProps;
  formProperties: DialogFormProps;
  gptProperties: DialogGptProps;
  mapProperties: DialogMapProps;
}

const DialogStyleFactory: React.FC<DialogStyleFactoryProps> = ({
  type,
  plugin,

  style,
  baseProps,

  defaultProperties,
  formProperties,
  gptProperties,
  mapProperties,
}) => {
  if (type === 'simple') {
    switch (plugin) {
      case 'forms':
        return (
          <SimpleFormDialog
            props={formProperties}
            base={baseProps}
            style={style}
          />
        );
      case 'map':
        return (
          <SimpleMapDialog
            props={mapProperties}
            base={baseProps}
            style={style}
          />
        );
      case 'gpt':
        return (
          <SimpleGptDialog
            props={gptProperties}
            base={baseProps}
            style={style}
          />
        );
      default:
      case 'default':
        return (
          <SimpleDialog
            props={defaultProperties}
            base={baseProps}
            style={style}
          />
        );
    }
  }

  return null;
};

export default DialogStyleFactory;
