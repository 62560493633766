import {useRef} from 'react';
import {useParams} from 'react-router-dom';
import TournamentBundleIntegration from '../../../components/scriptIntegrations/tournamentTasks/TournamentTaskEventHandler';
import TournamentProgressbarEventHandler, {
  TournamentProgressbarIntegrationRef,
} from '../../../components/scriptIntegrations/tournamentProgressbar/TournamentProgressbarEventHandler';
import {ProgressBarDataSample, TasksSampleData} from './TempDataSample';
import TournamentBundleFactory, {
  TournamentBundlesTypes,
} from './TournamentBundleFactory';
import TournamentTaskEventHandler from '../../../components/scriptIntegrations/tournamentTasks/TournamentTaskEventHandler';

const TournamentBundleWrapper: React.FC = () => {
  const {type, id} = useParams<{type: TournamentBundlesTypes; id: string}>();

  const tournamentProgressbarRef =
    useRef<TournamentProgressbarIntegrationRef>(null);

  return (
    <TournamentBundleIntegration>
      <TournamentProgressbarEventHandler
        ref={tournamentProgressbarRef}
        markTaskAsCompleted={(id) => {
          // request to server
        }}
        refresh={() => {
          // refresh from server
        }}
      />
      <TournamentTaskEventHandler />

      <TournamentBundleFactory
        type={type || 'simple'}
        progressBarProps={{
          progressbar: ProgressBarDataSample,
          mainColor: '#3C53CA',
          oppositeColor: 'white',

          onLoaded: () => {},
          onTaskCollected(id) {},
          onTaskCompleted(id) {},
        }}
        tasksProps={{
          tasks: TasksSampleData,
          mainColor: '#3C53CA',
          oppositeColor: 'white',
        }}
      />
    </TournamentBundleIntegration>
  );
};

export default TournamentBundleWrapper;
