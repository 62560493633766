import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import DailyQuestUiGameTab from './DailyQuestUiTab';
import {DailyQuestUiProps} from '../Types';
import DailyQuestUiGameLine from './DailyQuestUiGameLine';
import './DailyQuestUi.scoped.css';

const DailyQuestUiGame: React.FC<DailyQuestUiProps> = ({
  dailyQuests,
  onLoaded,
  onTaskCollected,
  onTaskCompleted,
}) => {
  const [activeTab, setActiveTab] = useState<'daily' | 'achievement'>('daily');

  useEffect(() => {
    onLoaded();
  }, []);

  return (
    <div className="w-full h-full relative py-[10%] md:py-[7%]">
      <div className="absolute w-full flex top-0 left-0 justify-center gap-0 md:gap-[5%]">
        <DailyQuestUiGameTab
          active={activeTab === 'daily'}
          onClick={() => setActiveTab('daily')}
          title="DAILY QUEST"
        />
        <DailyQuestUiGameTab
          active={activeTab === 'achievement'}
          onClick={() => setActiveTab('achievement')}
          title="ACHIEVEMENT"
        />
      </div>
      {activeTab === 'daily' && (
        <motion.div
          key="daily"
          animate={{x: [100, 0], opacity: [0, 1]}}
          transition={{duration: 0.5}}
          className="w-full h-full bg-[#9563E7] rounded-[20px] md:rounded-[50px] container-daily-quest flex justify-center items-center relative z-10 overflow-hidden"
        >
          <div className="daily-quest-spot-1 pointer-events-none" />
          <div className="daily-quest-spot-2 pointer-events-none" />
          <div className="p-[8%] w-full flex flex-col gap-4 md:gap-8 z-10">
            {dailyQuests.map((quest, index) => {
              return (
                <DailyQuestUiGameLine
                  key={index}
                  img={quest.img}
                  buttonTitle={quest.buttonTitle}
                  completed={quest.completed}
                  title={quest.title}
                  total={quest.total}
                  buttonOnClick={quest.buttonOnClick}
                  onTaskCollected={() => onTaskCollected(quest.id)}
                  onTaskCompleted={() => onTaskCompleted(quest.id)}
                />
              );
            })}
          </div>
        </motion.div>
      )}
      {activeTab === 'achievement' && (
        <motion.div
          key="achievement"
          animate={{x: [100, 0], opacity: [0, 1]}}
          transition={{duration: 0.5}}
          className="w-full h-full bg-[#9563E7] rounded-[20px] md:rounded-[50px] container-daily-quest flex justify-center items-center relative z-10 aspect-video"
        >
          <div className="daily-quest-spot-1 pointer-events-none" />
          <div className="daily-quest-spot-2 pointer-events-none" />
          <div className="p-[8%] w-full flex flex-wrap gap-8 z-10">
            {new Array(10).fill('').map((achi, index) => {
              return (
                <img
                  key={index}
                  src="./assets/dailyQuest/daily-quest-star.png"
                  alt="Achievement image"
                  className="object-contain object-center max-w-[12%]"
                />
              );
            })}
          </div>
        </motion.div>
      )}
    </div>
  );
};
export default DailyQuestUiGame;
