import {forwardRef, useCallback, useRef, useImperativeHandle} from 'react';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';

export interface SkyBoxImages {
  imageUrl: string;
  imageDepth: string;
}

export interface SkyboxWithModelCommunicatorSpawnModelGettingEvent {
  event: 'spawnModel';
  gltfModel: string;
}

export interface SkyboxWithModelCommunicatorSpawnSkyboxGettingEvent {
  event: 'spawnSkybox';
  skybox: SkyBoxImages;
}

export interface SkyboxWithModelCommunicatorOnLoadedSendingEvent {
  event: 'onLoaded';
}

interface SkyboxWithModelIntegrationProps {
  spawnModel: (gltfModel: string) => void;
  spawnSkybox: (skybox: SkyBoxImages) => void;
  componentId: string;
}

export interface SkyboxWithModelIntegrationRef {
  onLoaded: () => void;
}

const SkyboxWithModelEventHandler = forwardRef<
  SkyboxWithModelIntegrationRef,
  SkyboxWithModelIntegrationProps
>(({spawnModel, spawnSkybox, componentId}, ref) => {
  const eventMessangerRef =
    useRef<
      EventSystemRefProps<SkyboxWithModelCommunicatorOnLoadedSendingEvent>
    >(null);

  const incomingEventsHandler = useCallback(
    (
      message:
        | SkyboxWithModelCommunicatorSpawnModelGettingEvent
        | SkyboxWithModelCommunicatorSpawnSkyboxGettingEvent,
    ) => {
      switch (message.event) {
        case 'spawnModel':
          spawnModel(message.gltfModel);
          return;
        case 'spawnSkybox':
          spawnSkybox(message.skybox);
          return;
      }
    },
    [spawnModel, spawnSkybox],
  );

  useImperativeHandle(ref, () => ({
    onLoaded() {
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  return (
    <EventsMessanger<
      | SkyboxWithModelCommunicatorSpawnModelGettingEvent
      | SkyboxWithModelCommunicatorSpawnSkyboxGettingEvent,
      SkyboxWithModelCommunicatorOnLoadedSendingEvent
    >
      componentName={componentId}
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
});

export default SkyboxWithModelEventHandler;
