import React, {useEffect} from 'react';
import StemmyProgressBarGreen from '../../../progressBars/progressBarGreen/ProgressBarGreen';
import Button, {ButtonStyle} from '../../../form/button/Button';
import {ButtonSize} from '../../../buttons/yellowShort/StemmyYellowButton';
import {DailyQuestUiLineProps} from '../Types';

const DailyQuestUiGameLine: React.FC<DailyQuestUiLineProps> = ({
  title,
  completed,
  total,
  buttonOnClick = () => {},
  buttonTitle,
  img,
  onTaskCollected,
  onTaskCompleted,
}) => {
  useEffect(() => {
    if (total === completed) {
      onTaskCompleted();
    }
  }, [completed, total]);

  return (
    <div className="flex w-full justify-between flex-col md:flex-row gap-2 md:gap-0">
      <img
        src={img}
        alt={`Image quest ${title}`}
        className="object-contain object-center max-w-[12%] hidden md:block"
      />
      <div className="flex flex-col w-full max-w-full md:max-w-[61%] gap-2 md:gap-4">
        <div className="flex justify-center items-center daily-quest-title rounded-lg md:rounded-[12.4107px] py-1 w-full pl-[10%] shadow-md overflow-hidden">
          <span className="font-sigmarOne text-game-2xl md:text-game-base lg:text-game-xs text-white md:daily-quest-title-text">
            {title}
          </span>
        </div>
        <StemmyProgressBarGreen completed={completed} total={total} />
      </div>
      <div className="flex items-end max-w-full md:max-w-[24%]">
        <Button
          className="whitespace-nowrap w-full"
          btnSize={ButtonSize.SMALL}
          buttonStyle={ButtonStyle.Purple}
          fontFamily="font-sigmarOne"
          textStyles="text-game-2xl md:text-game-base lg:text-game-xs py-[3%]"
          onClick={() => {
            buttonOnClick();
            onTaskCollected();
          }}
          disabled={total !== completed}
        >
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
};
export default DailyQuestUiGameLine;
