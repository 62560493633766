import axios from 'axios';
import CheckIsOptionActive from '../helpers/CheckIsOptionActive';
import isDev from '../helpers/IsDevelopment';
import UseMocks, {UseProdMocks} from './Mocking.dev';

export const API_ROOT = process.env.REACT_APP_API_URI;

const {CancelToken} = axios;

export const getCancelToken = () => {
  const source = CancelToken.source();

  return {
    token: source.token,
    cancel: source.cancel,
  };
};

const APIRequest = axios.create({
  baseURL: API_ROOT,
});

// if (isDev() && CheckIsOptionActive('Use_Mocks')) {
//   UseMocks(APIRequest);
// }

// if (CheckIsOptionActive('Use_Dialog_Mocks')) {
// console.log('Using prod mocks');
UseProdMocks(APIRequest);
// }

export default APIRequest;
