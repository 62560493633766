import {AxiosError} from 'axios';
import {Store} from 'react-notifications-component';
import {ApiError} from '../../api/basicTypes/ApiError';

export const GlobalNotificationErrorHandler = (err: AxiosError<ApiError>) => {
  Store.addNotification({
    title: 'Whoops, something went wrong...',
    message: `${
      err.response && err.response.data
        ? err.response.data.message
        : err.message
    }`,
    type: 'danger',
    insert: 'top',
    container: 'bottom-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
