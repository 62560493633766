import {forwardRef, useCallback, useRef, useImperativeHandle} from 'react';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';
import {InformingCardProps, TaskCardProps} from '../../ui/informingCard/Types';

export interface FlyingCardsCommunicatorRefreshGettingEvent {
  event: 'refresh';
}

export interface FlyingCardsCommunicatorSpawnCardsGettingEvent {
  event: 'spawnCards';
  flyingCardsData: InformingCardProps[] | TaskCardProps[];
  coverCard: string;
}

export interface FlyingCardsCommunicatorOnLoadedSendingEvent {
  event: 'onLoaded';
}

export interface FlyingCardsCommunicatorCardOpenedByIdSendingEvent {
  event: 'cardOpenedById';
  id: string;
}

interface FlyingCardsIntegrationProps {
  spawnCards: (
    flyingCardsData: InformingCardProps[] | TaskCardProps[],
    coverCard: string,
  ) => void;
  refresh: () => void;
  componentId: string;
}

export interface FlyingCardsIntegrationRef {
  onLoaded: () => void;
  cardOpenedById: (id: string) => void;
}

const FlyingCardsEventHandler = forwardRef<
  FlyingCardsIntegrationRef,
  FlyingCardsIntegrationProps
>(({refresh, spawnCards, componentId}, ref) => {
  const eventMessangerRef =
    useRef<
      EventSystemRefProps<
        | FlyingCardsCommunicatorOnLoadedSendingEvent
        | FlyingCardsCommunicatorCardOpenedByIdSendingEvent
      >
    >(null);

  const incomingEventsHandler = useCallback(
    (
      message:
        | FlyingCardsCommunicatorRefreshGettingEvent
        | FlyingCardsCommunicatorSpawnCardsGettingEvent,
    ) => {
      switch (message.event) {
        case 'spawnCards':
          spawnCards(message.flyingCardsData, message.coverCard);
          return;
        case 'refresh':
          refresh();
          return;
      }
    },
    [refresh, spawnCards],
  );

  useImperativeHandle(ref, () => ({
    onLoaded() {
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    },
    cardOpenedById(id) {
      eventMessangerRef.current?.sendMessage({
        event: 'cardOpenedById',
        id,
      });
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  return (
    <EventsMessanger<
      | FlyingCardsCommunicatorRefreshGettingEvent
      | FlyingCardsCommunicatorSpawnCardsGettingEvent,
      | FlyingCardsCommunicatorOnLoadedSendingEvent
      | FlyingCardsCommunicatorCardOpenedByIdSendingEvent
    >
      componentName={componentId}
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
});

export default FlyingCardsEventHandler;
