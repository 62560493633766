import {Canvas} from '@react-three/fiber';
import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import EventsMessanger, {
  BaseMessagingEvent,
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';
import {BasicElement} from '../../helpers/BasicElement';

export interface ChestGettingEventOpen {
  event: 'Open';
}

export interface ChestGettingEventSetUpObject {
  text: string;
}

export interface ChestGettingEventSetUp {
  event: 'SetUp';
  payload: {
    playOnce?: boolean;
  };
  innerObject: ChestGettingEventSetUpObject;
}

interface ChestSendingEvent extends BaseMessagingEvent {
  event: 'OnInnerContentClick' | 'OnLoaded' | 'OnChestOpened';
}

export interface ChestScriptIntegrationProperties extends BasicElement {
  componentId: string;
  open: (playOnce: boolean) => void;
  setUp: (innerObject: ChestGettingEventSetUpObject) => void;
}

export interface ChestScriptIntegrationRef {
  onLoaded: () => void;
  onInnerContentClick: () => void;
  onChestOpened: () => void;
}

const ChestScriptIntegration = forwardRef<
  ChestScriptIntegrationRef,
  ChestScriptIntegrationProperties
>(({componentId, open, setUp, children}, ref) => {
  const eventMessangerRef =
    useRef<EventSystemRefProps<ChestSendingEvent>>(null);

  useImperativeHandle(ref, () => ({
    onLoaded: () => {
      eventMessangerRef.current?.sendMessage({
        event: 'OnLoaded',
      });
    },
    onInnerContentClick: () => {
      eventMessangerRef.current?.sendMessage({
        event: 'OnInnerContentClick',
      });
    },
    onChestOpened: () => {
      eventMessangerRef.current?.sendMessage({
        event: 'OnChestOpened',
      });
    },
  }));

  const incomingEventsHandler = useCallback(
    (message: ChestGettingEventOpen | ChestGettingEventSetUp) => {
      switch (message.event) {
        case 'Open':
          open(true);
          return;
        case 'SetUp':
          setUp(message.innerObject);
          return;
      }
    },
    [open, setUp],
  );

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'OnLoaded',
    });
  }, []);

  return (
    <div className="w-screen h-screen relative">
      <EventsMessanger<
        ChestGettingEventOpen | ChestGettingEventSetUp,
        ChestSendingEvent
      >
        componentName={componentId}
        myRef={eventMessangerRef}
        onMessage={incomingEventsHandler}
        onLoaded={OnLoaded}
      />
      <Canvas
        shadows
        camera={{rotation: [0, 0, 0], position: [0, 1.15, 5], fov: 28}}
      >
        <ambientLight color="#fff" intensity={0.3} />
        <pointLight color="#fff" position={[0, 5, 5]} />
        {children}
      </Canvas>
    </div>
  );
});

export default ChestScriptIntegration;
