import {forwardRef, useCallback, useRef, useImperativeHandle} from 'react';
import {
  IceBreakerCommunicatorGettingEvent,
  IceBreakerCommunicatorSendingEvent,
} from '../../../pages/scriptIntegrations/iceBreaker/CommonTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';

interface IceBreakerIntegrationProps {
  refresh: () => void;
}

export interface IceBreakerIntegrationRef {
  onLoaded: () => void;
  onIceBreaked: () => void;
}

const IceBreakerEventHandler = forwardRef<
  IceBreakerIntegrationRef,
  IceBreakerIntegrationProps
>(({refresh}, ref) => {
  const eventMessangerRef =
    useRef<EventSystemRefProps<IceBreakerCommunicatorSendingEvent>>(null);

  const incomingEventsHandler = useCallback(
    (message: IceBreakerCommunicatorGettingEvent) => {
      switch (message.event) {
        case 'refresh':
          refresh();
          return;
      }
    },
    [refresh],
  );

  useImperativeHandle(ref, () => ({
    onLoaded() {
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    },
    onIceBreaked() {
      eventMessangerRef.current?.sendMessage({
        event: 'onIceBreaked',
      });
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  return (
    <EventsMessanger<
      IceBreakerCommunicatorGettingEvent,
      IceBreakerCommunicatorSendingEvent
    >
      componentName="iceBreaker"
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
});

export default IceBreakerEventHandler;
