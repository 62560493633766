import {useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import DailyQuestEventHandler, {
  DailyQuestIntegrationRef,
} from '../../../components/scriptIntegrations/dailyQuest/DailyQuestEventHandler';
import DailyQuestUiWrapper from '../../../components/scriptIntegrations/dailyQuest/DailyQuestUiWrapper';
import DailyQuestUiGame from '../../../components/ui/dailyQuest/gameStyle/DailyQuestUiGame';

const DailyQuestGameWrapper: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [userId] = useState(searchParams.get('userId') || undefined);

  const dailyQuestRef = useRef<DailyQuestIntegrationRef>(null);

  return (
    <>
      <DailyQuestEventHandler
        ref={dailyQuestRef}
        markTaskAsCompleted={(id) => {
          // request to server
        }}
        refresh={() => {
          // refresh from server
        }}
      />
      <DailyQuestUiWrapper
        userId={userId}
        uiElement={DailyQuestUiGame}
        onLoaded={() => dailyQuestRef.current?.onLoaded()}
        onTaskCompleted={(id: string) =>
          dailyQuestRef.current?.onTaskCompleted(id)
        }
        onTaskCollected={(id: string) =>
          dailyQuestRef.current?.onTaskCompleted(id)
        }
      />
    </>
  );
};

export default DailyQuestGameWrapper;
