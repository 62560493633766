import {useRef} from 'react';
import IceBreakerEventHandler, {
  IceBreakerIntegrationRef,
} from '../../../components/scriptIntegrations/iceBreaker/IceBreakerEventHandler';
import IceBreakerUiWrapper from '../../../components/scriptIntegrations/iceBreaker/IceBreakerUiWrapper';
import IceBreakerUiSimple from '../../../components/ui/iceBreaker/simpleStyle/IceBreakerUiSimple';

const IceBreakerSimpleWrapper: React.FC = () => {
  const iceBreakerRef = useRef<IceBreakerIntegrationRef>(null);

  return (
    <>
      <IceBreakerEventHandler
        ref={iceBreakerRef}
        refresh={() => {
          console.log('refresh event');
        }}
      />
      <IceBreakerUiWrapper
        uiElement={IceBreakerUiSimple}
        onLoaded={() => iceBreakerRef.current?.onLoaded()}
        onIceBreaked={() => iceBreakerRef.current?.onIceBreaked()}
      />
    </>
  );
};

export default IceBreakerSimpleWrapper;
