import {TournamentProgressbar} from '../../../components/ui/tournamentProgressbar/Types';
import {TournamentTask} from '../../../components/ui/tournamentTasks/Types';

export const TasksSampleData: TournamentTask[] = [
  {
    id: 'F1D0AD91-15FD-43D0-9771-EA7E0714709F',
    isCompleted: true,
    isClaimed: true,
    title: 'Connect a wallet',
    description: 'Read more',
    points: 120,
    currency: 'pts',
  },
  {
    id: '8CFF5F21-555F-4AD9-BBAB-1C196F54ABB2',
    isCompleted: true,
    isClaimed: false,
    title: 'Post an Instagram story',
    description: 'Read more',
    points: 220,
    currency: 'pts',
  },
  {
    id: '6941DEB3-DA72-4C61-8926-1C298BD8DBFF',
    isCompleted: false,
    isClaimed: false,
    title: 'Get Riva Travel NFT',
    description: 'Read more',
    points: 200,
    currency: 'pts',
  },
];

export const ProgressBarDataSample: TournamentProgressbar = {
  id: 'asd9235ht',
  completed: 130,
  total: 700,
  stepForMarks: 50,
  prizes: [
    {
      id: 'prize01',
      img: '/assets/tournamentProgressbar/gift.png',
      textButton: 'Text Button',
      title: 'Title safsdf3',
      points: 69,
      onButtonClick: () => {
        console.log('prize01 clicked');
      },
    },
    {
      id: 'prize02',
      img: '/assets/tournamentProgressbar/gift.png',
      textButton: 'Text Button',
      title: 'Title fghhfdfh',
      points: 150,
    },
    {
      id: 'prize03',
      img: '/assets/tournamentProgressbar/gift.png',
      textButton: 'What is inside??',
      title: 'Title 234srg',
      points: 250,
      onButtonClick: () => {
        console.log('prize03 clicked');
      },
    },
    {
      id: 'prize04',
      img: '/assets/tournamentProgressbar/gift.png',
      textButton: 'What is inside??',
      title: 'Title 56dggdf',
      points: 370,
    },
    {
      id: 'prize05',
      img: '/assets/tournamentProgressbar/gift.png',
      textButton: 'What is inside??',
      title: 'Title dhf5435dg',
      points: 550,
    },
    {
      id: 'prize06',
      img: '/assets/tournamentProgressbar/gift.png',
      textButton: 'What is inside??',
      title: 'Title ghfjjhhg',
      points: 625,
    },
  ],
};
