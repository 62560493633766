import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import {DefaultModel} from '../types/basic';
import {BowBoxChestCharacterBaseModel} from '../../../types/models/v2/bases/BowBoxChestCharacterBase';

interface ChestCharacterBowBoxModelProps extends DefaultModel {
  onInnerContentClick: () => void;
  onBoxClick: () => void;
}

const ChestCharacterBowBoxModel: React.FC<ChestCharacterBowBoxModelProps> = ({
  onLoaded,
  onInnerContentClick,
  onBoxClick,
  position,
  rotation,
  scale,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials} = useGLTF(
    '/models/v2/fullModels/Box1.glb',
  ) as unknown as BowBoxChestCharacterBaseModel;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group
      ref={group}
      dispose={null}
      position={position}
      scale={scale}
      rotation={rotation}
      receiveShadow
      castShadow
    >
      <group name="Scene">
        <primitive object={nodes.ROOT} />
        <group name="Box">
          <skinnedMesh
            name="GiftBoxWithBow_mesh001"
            geometry={nodes.GiftBoxWithBow_mesh001.geometry}
            material={materials.Red}
            skeleton={nodes.GiftBoxWithBow_mesh001.skeleton}
          />
          <skinnedMesh
            name="GiftBoxWithBow_mesh001_1"
            geometry={nodes.GiftBoxWithBow_mesh001_1.geometry}
            material={materials.White}
            skeleton={nodes.GiftBoxWithBow_mesh001_1.skeleton}
            onClick={onBoxClick}
          />
        </group>
        <skinnedMesh
          name="Glow"
          geometry={nodes.Glow.geometry}
          material={materials.Glow}
          skeleton={nodes.Glow.skeleton}
        />
      </group>
    </group>
  );
};

export default ChestCharacterBowBoxModel;
