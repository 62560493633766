import {useThree} from '@react-three/fiber';
import {BackSide, TextureLoader} from 'three';
interface SkyboxProps {
  imageUrl: string;
  imageDepth: string;
  scale?: number;
}
const Skybox: React.FC<SkyboxProps> = ({imageUrl, imageDepth, scale = 500}) => {
  const {scene} = useThree();
  const loader = new TextureLoader();
  const texture = loader.load(imageUrl);
  const depth = loader.load(imageDepth);
  scene.background = texture;

  return (
    <mesh scale={[-scale, scale, scale]}>
      <sphereGeometry />
      <meshStandardMaterial
        side={BackSide}
        normalMap={depth}
        map={texture}
      ></meshStandardMaterial>
    </mesh>
  );
};
export default Skybox;
