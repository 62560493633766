import {AiOutlineClose} from 'react-icons/ai';

interface LevelupPopupBasicProps {
  onClose: () => void;
}

const LevelupPopupBasic: React.FC<LevelupPopupBasicProps> = ({onClose}) => {
  return (
    <div className="rounded-2xl bg-white mx-auto w-fit max-w-screen-md p-4 font-beVietnamPro text-[#2A2F3F] relative">
      <div className="flex items-center gap-4 ">
        <div className="relative">
          <img src="/assets/level/background.png" className="w-16 md:w-24" />
          <img
            src="/assets/level/crown1.png"
            className="w-18 md:w-32 absolute top-0 left-0 right-0"
          />
        </div>
        <div className="font-bold text-4xl md:text-6xl">New Level!</div>
        <div className="w-12">
          <div className="absolute right-2 top-2">
            <AiOutlineClose
              className="w-6 h-6 hover:w-8 hover:h-8 transition-all duration-75 cursor-pointer"
              onClick={() => onClose()}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center justify-center">
        <div className="flex gap-2 items-center">
          <div className="">
            <img src="/assets/level/coins.png" className="w-12" />
          </div>
          <div className="">
            <div className="font-semibold text-3xl">500</div>
            <div>Coins earned</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelupPopupBasic;
