import {animated, config, useSpring} from '@react-spring/three';
import {useGLTF} from '@react-three/drei';
import {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Vector3} from 'three';
import {
  RobotJetpackCharacterBase,
  RobotJetpackCharacterBaseAnimations,
} from '../../../types/models/v2/bases/RobotJetpackCharacterBase';
import {DefaultFadeAnimation} from '../../models/animations/AnimationFadePrefabs';
import AnimationLoader, {
  AnimationLoaderRefProps,
} from '../../models/animations/AnimationLoader';
import {LoopAnimation, RepeatOnceAnimation} from '../../models/animations/AnimationRepeatPrefabs';
import RobotJetpackCharacter from '../../models/bases/RobotJetpackCharacter';
import {OnboardingJetpackProps, OnboardingJetpackRef} from './Contract';

const RobotJetpack = forwardRef<OnboardingJetpackRef, OnboardingJetpackProps>(
  ({onLoaded, isDefaultGrown}, ref) => {
    const {animations} = useGLTF(
      '/models/v2/fullModels/RoboJet.glb',
    ) as unknown as RobotJetpackCharacterBase;

    const [isGrown, setIsGrown] = useState(isDefaultGrown);

    const [characterGroup, setCharacterGroup] = useState<THREE.Group>();
    const animatorRef =
      useRef<AnimationLoaderRefProps<RobotJetpackCharacterBaseAnimations>>(
        null,
      );

    const {positionX, positionY, scale} = useSpring({
      positionX: isGrown ? 0 : 0,
      positionY: isGrown ? -0.5 : -0.5,
      scale: isGrown ? 1.2 : 1.1,
      config: config.stiff,
    });

    useImperativeHandle(ref, () => ({
      grow(isAnimate, duration = 1, playmode = 'once') {
        setIsGrown(true);
        animatorRef.current?.animate('Jump1', {
          repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
          duration,
          onFinished: {
            launchBaseAnimation: {
              fadeMode: DefaultFadeAnimation,
            },
          },
        });
      },
      shrink(isAnimate, duration = 1, playmode = 'once') {
        setIsGrown(false);
        animatorRef.current?.animate('Jump2', {
          repeatMode: playmode === 'once' ? RepeatOnceAnimation : LoopAnimation,
          duration,
          onFinished: {
            launchBaseAnimation: {
              fadeMode: DefaultFadeAnimation,
            },
          },
        });
      },
      // Bug in animations - right and left are mixed
      pointUpRight(playOnce) {
        animatorRef.current?.animate('PointLeftUp', {
          repeatMode: playOnce ? RepeatOnceAnimation : undefined,
          onFinished: playOnce
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
        });
      },
      pointRight(playOnce) {
        animatorRef.current?.animate('PointLeft', {
          repeatMode: playOnce ? RepeatOnceAnimation : undefined,
          onFinished: playOnce
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
        });
      },
      pointDownRight(playOnce) {
        animatorRef.current?.animate('PointLeftDown', {
          repeatMode: playOnce ? RepeatOnceAnimation : undefined,
          onFinished: playOnce
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
        });
      },

      pointUpLeft(playOnce) {
        animatorRef.current?.animate('PointRightUp', {
          repeatMode: playOnce ? RepeatOnceAnimation : undefined,
          onFinished: playOnce
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
        });
      },
      pointLeft(playOnce) {
        animatorRef.current?.animate('PointRight', {
          repeatMode: playOnce ? RepeatOnceAnimation : undefined,
          onFinished: playOnce
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
        });
      },
      pointDownLeft(playOnce) {
        animatorRef.current?.animate('PointRightDown', {
          repeatMode: playOnce ? RepeatOnceAnimation : undefined,
          onFinished: playOnce
            ? {
                launchBaseAnimation: {
                  fadeMode: DefaultFadeAnimation,
                },
              }
            : undefined,
        });
      },

      dance() {
        console.log('DANCE ANIMATION LAUNCHNGN!!!!! NANNA')
        animatorRef.current?.animateRandom(['Dance1', 'Dance2']);
      },
      land() {
        animatorRef.current?.animate('Idle');
      },
      takeoff() {
        animatorRef.current?.animate('Fly');
      },
      wave() {
        animatorRef.current?.animate('Wave');
      },

      idle() {
        animatorRef.current?.animate('Idle');
      },

      cry() {
        
        animatorRef.current?.animate('Cry')
      }
    }));

    return (
      <>
        <pointLight color="#fff" position={[0, 5, 5]} />
        <animated.group
          position-x={positionX}
          position-y={positionY}
          position-z={0}
          scale={scale}
        >
          <RobotJetpackCharacter
            onLoaded={setCharacterGroup}
            scale={new Vector3(1.1, 1.1, 1.1)}
            position={new Vector3(0, 0, 0)}
          />
        </animated.group>
        {characterGroup && (
          <AnimationLoader
            group={characterGroup}
            animations={animations}
            myRef={animatorRef}
            baseAnimation="Wave"
            onLoaded={onLoaded}
          />
        )}
      </>
    );
  },
);

export default RobotJetpack;
