import MockAdapter from 'axios-mock-adapter/types';
import {TournamentAnonymusDTO} from '../dto/TournamentDTO';

export const UseTournamentMock = (adapter: MockAdapter): void => {
  adapter
    .onPost(/\/Integration\/GetTournamentData/)
    .reply<TournamentAnonymusDTO[]>(() => {
      const dialog: TournamentAnonymusDTO[] = [
        {
          position: 0,
          name: '0xbF4...BD23',
          result: 1000,
          isSelf: false,
        },
        {
          position: 1,
          name: '0xbF4...BD23',
          result: 970.12,
          isSelf: false,
        },
        {
          position: 2,
          name: '0xbF4...BD23',
          result: 941.25,
          isSelf: false,
        },
        {
          position: 3,
          name: '0xbF4...BD23',
          result: 50250.0,
          isSelf: false,
        },
        {
          position: 4,
          name: '0xbF4...BD23',
          result: 550750.0,
          isSelf: false,
        },
        {
          position: 5,
          name: '0xbF4...BD23',
          result: 50250.0,
          isSelf: false,
        },
      ];

      return [200, dialog];
    });
};
