import classNames from 'classnames';

interface FlyingCardParagraphProps
  extends React.HTMLProps<HTMLParagraphElement> {
  text: string;
}

const FlyingCardParagraph: React.FC<FlyingCardParagraphProps> = ({
  text,
  className,
  ...other
}) => {
  return (
    <p
      className={classNames(
        'text-center font-beVietnamPro font-semibold leading-[normal] tracking-[-0.222px] text-white text-[1.6vw]',
        className,
      )}
      {...other}
    >
      {text}
    </p>
  );
};
export default FlyingCardParagraph;
