import {
  DataField,
  DataFieldResponse,
  DialogFormsInput,
} from '../ui/dialog/FormsPluginContract';
import InputDataField from './input/InputDataField';

export interface DataFieldCommonProps {
  data: DataField;
  containerProps?: React.HTMLProps<HTMLDivElement>;
  onDataUpdated: (isValid: boolean, value?: DataFieldResponse) => void;
}

const DataFieldFactory: React.FC<DataFieldCommonProps> = ({
  data,
  onDataUpdated,
  containerProps,
}) => {
  switch (data.inputType) {
    default:
    case 'input':
      return (
        <InputDataField
          data={data as DialogFormsInput}
          onDataUpdated={onDataUpdated}
          containerProps={containerProps}
        />
      );
  }
};

export default DataFieldFactory;
